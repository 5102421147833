import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getErrorMessage, getResponseData } from "utils/helpers/apiDataHelpers";
import { toast } from "utils/hooks/useToast";
import { http } from "utils/httpRequests/http";

export const getRedemptions = createAsyncThunk(
  "redemption/getRedemptions",
  async (data, { getState, rejectWithValue }) => {
    const { page, limit } = getState().redemption;
    const { userRole } = getState().auth;
    const url =
      userRole === "admin" || userRole === "superAdmin"
        ? "/redemptions/admin"
        : "/redemptions";
    try {
      const response = await http.get(url, {
        params: {
          page: data.page ? data.page : page,
          limit: data.limit ? data.limit : limit,
          ...data,
        },
      });
      const responseData = getResponseData(response);
      responseData &&
        responseData?.results &&
        responseData?.results.forEach((item, index) => {
          const pageStart = (responseData?.page - 1) * responseData?.limit;
          item.serialNumber = pageStart + index + 1;
        });
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const validateinvoices = createAsyncThunk(
  "redemption/validateinvoices",
  async (data = {}, { rejectWithValue }) => {
    try {
      const response = await http.post(`/invoices/valid`, data);
      toast.success("invoice Id's are successfully validated");
      return getResponseData(response);
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const redeemInvoices = createAsyncThunk(
  "redemption/redeemInvoices",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.post(`/redemptions/cash`, {
        phoneToken: data.token,
        invoicesId: data.invoicesId,
        phone: data.phone,
      });
      toast.success("Invoices are redeemed successfully");

      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const payInvoices = createAsyncThunk(
  "redemption/payInvoices",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.post(`/redemptions/donation`, {
        redeemsId: data?.selectedRowsData,
      });
      toast.success("Redemptions are paid successfully");
      data?.navigate("/redemptions?page=1&limit=20");
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

const initialState = {
  loading: false,
  results: [],
  selectedRowsData: [],
  validate: false,
  isPhoneNumberVerified: false,
  page: 1,
  limit: 20,
  totalPages: 0,
  totalResults: 0,
  error: "",
};

const redemptionSlice = createSlice({
  name: "redemption",
  initialState,
  reducers: {
    resetState: () => initialState,
    resetRedemptionState: (state) => {
      state.redemption = null;
    },
    setIsPhoneNumberVerified: (state) => {
      state.isPhoneNumberVerified = true;
    },
    setSelectedRowsData: (state, action) => {
      state.selectedRowsData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRedemptions.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getRedemptions.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.results = action.payload?.results;
        state.page = action.payload?.page;
        state.limit = action.payload?.limit;
        state.totalResults = action.payload?.totalResults;
        state.totalPages = action.payload?.totalPages;
      })
      .addCase(getRedemptions.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(redeemInvoices.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(redeemInvoices.fulfilled, (state) => {
        state.loading = false;
        state.error = "";
        state.isPhoneNumberVerified = true;
      })
      .addCase(redeemInvoices.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(validateinvoices.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(validateinvoices.fulfilled, (state) => {
        state.loading = false;
        state.error = "";
        state.validate = true;
      })
      .addCase(validateinvoices.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(payInvoices.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(payInvoices.fulfilled, (state) => {
        state.loading = false;
        state.error = "";
        state.selectedRowsData = [];
      })
      .addCase(payInvoices.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      });
  },
});

// Action creators generated for each case reducer function
export const { resetState, resetRedemptionState, setSelectedRowsData } =
  redemptionSlice.actions;

// Exporting default reducer
export default redemptionSlice.reducer;
