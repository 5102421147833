import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import DataGridSearch from "components/DataGridSearch";
import queryString from "query-string";
import { memo, useCallback, useLayoutEffect, useState } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import TABLE_FILTER from "utils/constants/tableFilter";

function Filters() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = queryString.parse(search);
  const [value, setValue] = useState("all");
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleChange = useCallback(
    (_, value) => {
      setValue(value);
      // Necessary for performance
      // eslint-disable-next-line no-unused-vars
      const { active, ...rest } = query;
      let params = { ...rest };
      switch (value) {
        case TABLE_FILTER.ACTIVE:
          params.active = TABLE_FILTER.ACTIVE;
          break;
        case TABLE_FILTER.INACTIVE:
          params.active = TABLE_FILTER.INACTIVE;
          break;
        default:
          break;
      }
      navigate({
        pathname: "/master-table",
        search: createSearchParams(params).toString(),
      });
    },
    [navigate, query]
  );

  useLayoutEffect(() => {
    if (query.active) {
      setValue(query.active);
    }
  }, [query.active]);

  return (
    <Box sx={containerStyles}>
      <Box
        sx={{
          backgroundColor: "white",
          marginBottom: { xs: 2, md: 0 },
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Product status filters"
        >
          <Tab label="All Tables" value="all" />
          <Tab label="Active" value={TABLE_FILTER.ACTIVE} />
          <Tab label="Inactive" value={TABLE_FILTER.INACTIVE} />
        </Tabs>
      </Box>
      <Box display={isSmall ? "grid" : "flex"}>
        <DataGridSearch
          searchKeyParam="name"
          searchKeyParamValue="label"
          searchQueryParam="value"
        />
      </Box>
    </Box>
  );
}

export default memo(Filters);

const containerStyles = {
  display: { xs: "grid", md: "flex" },
  justifyContent: "space-between",
  alignItems: "center",
  "& .styled-button": {
    borderRadius: "1px",
    padding: "10px",
  },
  "& .MuiOutlinedInput-root": { borderRadius: "20px" },
  "& .MuiTab-root": { textTransform: "capitalize" },
};
