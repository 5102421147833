import Stack from "@mui/material/Stack";
import DataGrid from "components/Invoices/DataGrid";
import DateFilters from "components/Invoices/DateFilters";
import Filters from "components/Invoices/Filters";
import PageHeader from "components/PageHeader";
import { memo } from "react";

const Invoices = () => {
  return (
    <Stack direction="column" spacing={3}>
      <PageHeader title="Invoices" />
      <Stack
        direction={{ xs: "column-reverse", md: "row" }}
        spacing={3}
        alignItems={{ xs: "flex-start", md: "center" }}
        justifyContent={{ xs: "center", md: "space-between" }}
      >
        <Filters />
        <DateFilters />
      </Stack>
      <DataGrid />
    </Stack>
  );
};

export default memo(Invoices);
