import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getErrorMessage, getResponseData } from "utils/helpers/apiDataHelpers";
import { toast } from "utils/hooks/useToast";
import http from "utils/httpRequests/http";

export const getPreferences = createAsyncThunk(
  "preferences/getPreferences",
  async (params, { rejectWithValue }) => {
    try {
      const response = await http.get(`/preferences`, {
        params: {
          ...params,
          page: params.page ?? 1,
          limit: params.limit ?? 20,
        },
      });
      const responseData = getResponseData(response);

      responseData &&
        responseData?.results.forEach((item, index) => {
          const pageStart = (responseData?.page - 1) * responseData?.limit;
          item.serialNumber = pageStart + index + 1;
        });

      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getPreference = createAsyncThunk(
  "preferences/getPreference",
  async (id, { getState, fulfillWithValue, rejectWithValue }) => {
    const { results } = getState().preferences.preferences;
    const preference = results.find((preference) => preference.id === id);
    if (preference) return fulfillWithValue(preference);
    try {
      const response = await http.get(`/preferences/${id}`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const createPreference = createAsyncThunk(
  "preferences/createPreference",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.post(`/preferences`, data);
      const responseData = getResponseData(response);
      toast.success("Preference created successfully");
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const updatePreference = createAsyncThunk(
  "preferences/updatePreference",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await http.patch(
        `/preferences/${payload.id}`,
        payload.data
      );
      const responseData = getResponseData(response);
      toast.success("Preference updated successfully");
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const deletePreference = createAsyncThunk(
  "preferences/deletePreference",
  async (id, { getState, rejectWithValue }) => {
    const { results, page, limit } = getState().preferences.preferences;
    try {
      await http.delete(`/preferences/${id}`);
      toast.success("Preference deleted successfully");
      let updatedResults = results.filter((preference) => preference.id !== id);
      if (updatedResults.length) {
        return updatedResults.map((item, index) => {
          const pageStart = (page - 1) * limit;
          return {
            ...item,
            serialNumber: pageStart + index + 1,
          };
        });
      }
      return updatedResults;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

const initialState = {
  loading: {
    preferences: true,
    preference: true,
  },
  preferences: {
    results: [],
    page: 1,
    limit: 20,
    totalPages: 0,
    totalResult: 0,
  },
  preference: null,
  error: {
    preferences: "",
    preference: "",
  },
};

const preferencesSlice = createSlice({
  name: "preferences",
  initialState,
  reducers: {
    resetPreferencesState: () => initialState,
    resetPreferenceState: (state) => {
      state.loading.preference = initialState.loading.preference;
      state.preference = initialState.preference;
      state.error.preference = initialState.error.preference;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPreferences.pending, (state) => {
        state.loading.preferences = true;
      })
      .addCase(getPreferences.fulfilled, (state, action) => {
        state.loading.preferences = false;
        state.preferences = action.payload;
      })
      .addCase(getPreferences.rejected, (state, action) => {
        state.loading.preferences = false;
        state.error.preferences = action.payload;
      })
      .addCase(getPreference.pending, (state) => {
        state.loading.preference = true;
      })
      .addCase(getPreference.fulfilled, (state, action) => {
        state.loading.preference = false;
        state.preference = action.payload;
      })
      .addCase(getPreference.rejected, (state, action) => {
        state.loading.preference = false;
        state.error.preference = action.payload;
      })
      .addCase(createPreference.pending, (state) => {
        state.loading.preference = true;
        state.error.preference = "";
      })
      .addCase(createPreference.fulfilled, (state, action) => {
        state.loading.preference = false;
        state.preference = action.payload;
      })
      .addCase(createPreference.rejected, (state, action) => {
        state.loading.preference = false;
        state.error.preference = action.payload;
      })
      .addCase(updatePreference.pending, (state) => {
        state.loading.preference = true;
        state.error.preference = "";
      })
      .addCase(updatePreference.fulfilled, (state, action) => {
        state.loading.preference = false;
        state.preference = action.payload;
      })
      .addCase(updatePreference.rejected, (state, action) => {
        state.loading.preference = false;
        state.error.preference = action.payload;
      })
      .addCase(deletePreference.pending, (state) => {
        state.loading.preference = true;
        state.error.preference = "";
      })
      .addCase(deletePreference.fulfilled, (state, action) => {
        state.loading.preference = false;
        state.preferences.results = action.payload;
      })
      .addCase(deletePreference.rejected, (state, action) => {
        state.loading.preference = false;
        state.error.preference = action.payload;
      });
  },
});

export const { resetPreferencesState, resetPreferenceState } =
  preferencesSlice.actions;

export default preferencesSlice.reducer;
