import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Actions from "components/Mastertable/Actions";
import DataGrid from "components/Mastertable/DataGrid";
import Filters from "components/Mastertable/Filters";
import MasterTableForm from "components/Mastertable/MasterTableForm";
import DateFilters from "components/Mastertable/MasterTableForm/DateFilters";
import PageHeader from "components/PageHeader";
import PropTypes from "prop-types";
import { memo } from "react";

const MasterTable = ({ create, update }) => {
  if (create || update) {
    return <MasterTableForm />;
  }

  return (
    <Stack direction="column" spacing={3}>
      <PageHeader title="Master Table" />
      <Box display="flex" justifyContent="space-between">
        <Actions />
        <DateFilters />
      </Box>
      <Filters />
      <DataGrid />
    </Stack>
  );
};

export default memo(MasterTable);

MasterTable.propTypes = {
  create: PropTypes.bool,
  update: PropTypes.bool,
};

MasterTable.defaultProps = {
  create: false,
  update: false,
};
