import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { memo, useState } from "react";
import { useSelector } from "react-redux";
import GeneralSettings from "./GeneralSettings";
import StyledVerticalTabPanel from "./StyledVerticalTabPanel";
import UserAccountInformation from "./UserAccountInformation";
import UserPasswordReset from "./UserPasswordReset";

const SettingSideTabs = () => {
  const [value, setValue] = useState(0);
  const userRole = useSelector((state) => state.auth?.userRole);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Box py={2} px={1}>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{
              borderRight: 1,
              borderColor: "divider",
            }}
          >
            <Tab
              sx={{
                alignItems: "baseline",
                fontWeight: "700",
              }}
              label="General Settings"
            />
            <Tab
              sx={{ alignItems: "baseline", fontWeight: "700" }}
              label="Password Reset"
            />
            {userRole === "supplier" && (
              <Tab
                sx={{ alignItems: "baseline", fontWeight: "700" }}
                label="Account Info"
              />
            )}
          </Tabs>
        </Grid>
        <Grid item xs={10}>
          <Card
            variant="outlined"
            sx={{
              display: "flex",
              borderRadius: 1.5,
            }}
          >
            <StyledVerticalTabPanel value={value} index={0}>
              <GeneralSettings />
            </StyledVerticalTabPanel>
            <StyledVerticalTabPanel value={value} index={1}>
              <UserPasswordReset />
            </StyledVerticalTabPanel>
            {userRole === "supplier" && (
              <StyledVerticalTabPanel value={value} index={2}>
                <UserAccountInformation />
              </StyledVerticalTabPanel>
            )}
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(SettingSideTabs);
