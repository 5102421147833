import { loadMasterTable } from "components/Mastertable/MasterTableForm";
import { loadUser } from "components/Users/UserForm";
import Layout from "layout";
import NotFound from "pages/404";
import AddPhoneNumber from "pages/AddPhoneNumber";
import Dashboard from "pages/Dashboard";
import Invoices from "pages/Invoices";
import LoginPage from "pages/Login";
import MasterTable from "pages/MasterTable";
import Preferences from "pages/Preferences";
import RecoverPassword from "pages/RecoverPassword";
import Redemption from "pages/Redemption";
import ResetPassword from "pages/ResetPassword";
import Settings from "pages/Settings";
import Users from "pages/Users";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import AuthRoute from "routes/AuthRoute";
import ProtectedRoute from "routes/ProtectedRoute";
import AdminRoute from "./AdminRoute";
import AuthPhoneNumberRoute from "./AuthPhoneNumberRoute";
import SuperAdminRoute from "./SuperAdminRoute";

const routes = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<Layout />}>
          <Route index element={<Dashboard />} />
          <Route path="dashboard">
            <Route index element={<Dashboard />} />
          </Route>
          <Route path="users">
            <Route index element={<Users />} />
            <Route path="create" element={<Users create />} />

            <Route
              path=":id/update"
              element={<Users update />}
              loader={loadUser}
            />
            <Route path="preferences" element={<AdminRoute />}>
              <Route index element={<Preferences />} />
              <Route element={<SuperAdminRoute />}>
                <Route path="create" element={<Preferences create />} />
                <Route path=":id/update" element={<Preferences update />} />
              </Route>
            </Route>
          </Route>

          <Route path="master-table">
            <Route index element={<MasterTable />} />
            <Route path="create" element={<MasterTable create />} />
            <Route
              path=":id/update"
              element={<MasterTable update />}
              loader={loadMasterTable}
            />
          </Route>

          <Route path="invoices">
            <Route index element={<Invoices />} />
            <Route path="create" element={<Invoices create />} />
          </Route>

          <Route path="redemptions">
            <Route index element={<Redemption />} />
            <Route path="redeem" element={<Redemption create />} />
          </Route>

          <Route path="settings" element={<Settings />} />
        </Route>
      </Route>

      <Route element={<AuthRoute />}>
        <Route path="login" element={<LoginPage />} />
      </Route>

      <Route path="phone-number" element={<AuthPhoneNumberRoute />}>
        <Route index element={<AddPhoneNumber />} />
      </Route>

      <Route path="recover-password" element={<RecoverPassword />} />
      <Route path="reset-password" element={<ResetPassword />} />

      <Route path="*" element={<NotFound />} />
    </>
  )
);

export default routes;
