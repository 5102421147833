import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import BarChartIcon from "@mui/icons-material/BarChart";
import ContactEmergencyIcon from "@mui/icons-material/ContactEmergency";
import ContactsIcon from "@mui/icons-material/Contacts";
import LegendToggleIcon from "@mui/icons-material/LegendToggle";
import PaymentsIcon from "@mui/icons-material/Payments";
import PeopleIcon from "@mui/icons-material/People";
import ReceiptIcon from "@mui/icons-material/Receipt";
import RedeemIcon from "@mui/icons-material/Redeem";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import AnalyticTabPanel from "components/Dashboard/Analytics/AnalyticTabPanel";
import AnalyticCards from "components/Dashboard/Analytics/AnalyticsCards";
import PageHeader from "components/PageHeader";
import DatePicker from "components/Pickers/DatePicker";
import dayjs from "dayjs";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import { getAnalytics, resetState } from "store/slices/dashboardSlice";
import USER_ROLE from "utils/constants/userRole";
import useQueryParams from "utils/hooks/useQueryParams";

function Dashboard() {
  const navigate = useNavigate();
  const params = useQueryParams();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.dashboard?.loading?.analytics);
  const analytics = useSelector((state) => state.dashboard?.analytics);
  const loggedUserRole = useSelector((state) => state.auth?.userRole);
  const [tabValue, setTabValue] = useState(0);

  const isAdmin =
    loggedUserRole === USER_ROLE.SUPER_ADMIN ||
    loggedUserRole === USER_ROLE.ADMIN;

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const invoices = [
    {
      heading: "Invoice",
      currMonthValue: analytics?.invoices?.currMonthInvoices || 0,
      prevMonthValue: analytics?.invoices?.prevMonthInvoices || 0,
      icon: <ReceiptIcon />,
    },
    {
      heading: "Sale",
      currMonthValue: analytics?.invoices?.currMonthSale || 0,
      prevMonthValue: analytics?.invoices?.prevMonthSale || 0,
      icon: <BarChartIcon />,
    },
    {
      heading: "Gold",
      currMonthValue: analytics?.invoices?.currMonthGold || 0,
      prevMonthValue: analytics?.invoices?.prevMonthGold || 0,
      icon: <LegendToggleIcon />,
    },
  ];

  const redemptions = [
    {
      heading: "Redemption",
      currMonthValue: analytics?.redemption?.currMonthRedemption || 0,
      prevMonthValue: analytics?.redemption?.prevMonthRedemption || 0,
      icon: <RedeemIcon />,
    },
    {
      heading: "Amount",
      currMonthValue: analytics?.redemption?.currMonthAmount || 0,
      prevMonthValue: analytics?.redemption?.prevMonthAmount || 0,
      icon: <PaymentsIcon />,
    },
    {
      heading: "Gold",
      currMonthValue: analytics?.redemption?.currMonthGold || 0,
      prevMonthValue: analytics?.redemption?.prevMonthGold || 0,
      icon: <LegendToggleIcon />,
    },
    {
      heading: "Donation",
      currMonthValue: analytics?.redemption?.currMonthDonation || 0,
      prevMonthValue: analytics?.redemption?.prevMonthDonation || 0,
      icon: <VolunteerActivismIcon />,
    },
  ];

  const users = [
    {
      heading: "Super Admin",
      currMonthValue: analytics?.users?.currMonthUsers?.superAdmin || 0,
      prevMonthValue: analytics?.users?.prevMonthUsers?.superAdmin || 0,
      icon: <AdminPanelSettingsIcon />,
    },
    {
      heading: "Admin",
      currMonthValue: analytics?.users?.currMonthUsers?.admin || 0,
      prevMonthValue: analytics?.users?.prevMonthUsers?.admin || 0,
      icon: <SupervisedUserCircleIcon />,
    },
    {
      heading: "Gold Partners",
      currMonthValue: analytics?.users?.currMonthUsers?.goldPartner || 0,
      prevMonthValue: analytics?.users?.prevMonthUsers?.goldPartner || 0,
      icon: <ContactsIcon />,
    },
    {
      heading: "Sale Agent",
      currMonthValue: analytics?.users?.currMonthUsers?.saleAgent || 0,
      prevMonthValue: analytics?.users?.prevMonthUsers?.saleAgent || 0,
      icon: <ContactEmergencyIcon />,
    },
    {
      heading: "Total Users",
      currMonthValue: analytics?.users?.currMonthTotalUsers || 0,
      prevMonthValue: analytics?.users?.prevMonthTotalUsers || 0,
      icon: <PeopleIcon />,
    },
  ];

  const totalUsers = [
    {
      heading: "Total Users",
      currMonthValue: analytics?.totalUsers?.totalUsers || 0,
      icon: <PeopleIcon />,
    },
  ];

  const handleDateChange = useCallback(
    (value) => {
      navigate({
        search: createSearchParams({
          ...params,
          date: dayjs(value).format("YYYY-MM-DD"),
        }).toString(),
      });
    },
    [navigate, params]
  );

  function tabProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  useEffect(() => {
    dispatch(getAnalytics(params.date || ""));
    return () => dispatch(resetState());
  }, [dispatch, params]);

  return (
    <Stack direction="column" spacing={3}>
      <Box display="flex" alignItems="flex-end" gap={2}>
        <PageHeader title="Monthly Analytics" />
        <CustomBreadcrumbs />
      </Box>
      {loading ? (
        <Box
          pt="10vh"
          display="flex"
          justifyContent={"center"}
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      ) : Object.keys(analytics).length ? (
        <Stack direction="column" spacing={3}>
          <Stack
            direction={{ xs: "column-reverse", md: "row" }}
            justifyContent={{ xs: "center", md: "space-between" }}
            alignItems={{ xs: "flex-start", md: "center" }}
            spacing={3}
          >
            <Tabs
              indicatorColor="none"
              sx={{
                "& .MuiTab-root.Mui-selected": {
                  backgroundColor: "primary.main",
                  color: "#fff",
                  borderRadius: 1,
                  fontSize: "1.2rem",
                },
                "& .MuiTab-root": {
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                },
              }}
              value={tabValue}
              onChange={handleChange}
            >
              <Tab label="Invoices" {...tabProps(0)} />
              <Tab label="Redemptions" {...tabProps(1)} />
              <Tab
                label="Users"
                {...tabProps(2)}
                style={{
                  display: isAdmin ? "block" : "none",
                }}
              />
              <Tab
                label="Total Users"
                {...tabProps(3)}
                style={{
                  display: isAdmin ? "block" : "none",
                }}
              />
            </Tabs>
            <DatePicker onChange={handleDateChange} />
          </Stack>
          <Box>
            <AnalyticTabPanel value={tabValue} index={0}>
              <AnalyticCards data={invoices} />
            </AnalyticTabPanel>
            <AnalyticTabPanel value={tabValue} index={1}>
              <AnalyticCards data={redemptions} />
            </AnalyticTabPanel>
            {isAdmin && (
              <>
                <AnalyticTabPanel value={tabValue} index={2}>
                  <AnalyticCards data={users} />
                </AnalyticTabPanel>
                <AnalyticTabPanel value={tabValue} index={3}>
                  <AnalyticCards data={totalUsers} />
                </AnalyticTabPanel>
              </>
            )}
          </Box>
        </Stack>
      ) : (
        <Typography
          variant="h6"
          component="h2"
          color="text.disabled"
          fontWeight={600}
          textAlign="center"
          pt={5}
        >
          No data found
        </Typography>
      )}
    </Stack>
  );
}

export default Dashboard;
