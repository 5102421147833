import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getErrorMessage, getResponseData } from "utils/helpers/apiDataHelpers";
import { toast } from "utils/hooks/useToast";
import http from "utils/httpRequests/http";

export const getStores = createAsyncThunk(
  "filters/getStores",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.get(`/sellerDetail`, {
        params: {
          ...data,
        },
      });
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const getAllMarkets = createAsyncThunk(
  "filters/getAllMarkets",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.get(`markets/getAllMarkets`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const getAllCities = createAsyncThunk(
  "filters/getAllCities",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.get(`address/cities`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const getUsers = createAsyncThunk(
  "filters/getUsers",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.get(`/users`, {
        params: {
          ...data,
          page: data.page ?? 1,
          limit: data.limit ?? 10,
        },
      });
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

const initialState = {
  stores: {},
  categories: [],
  flattenedCategories: [],
  markets: [],
  cities: [],
  loading: true,
  error: "",
  users: [],
};

const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    resetState: () => initialState,
    resetStoreState: (state) => {
      state.stores = null;
    },
    addQueryParams: (state, action) => {
      state.queryParams = action.payload;
    },
    appendUser: (state, action) => {
      state.users = [...state.users, action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStores.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getStores.fulfilled, (state, action) => {
        state.stores = action.payload.results;
        state.loading = false;
      })
      .addCase(getStores.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getAllMarkets.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getAllMarkets.fulfilled, (state, action) => {
        state.markets = action.payload;
        state.loading = false;
      })
      .addCase(getAllMarkets.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getAllCities.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getAllCities.fulfilled, (state, action) => {
        state.cities = action.payload;
        state.loading = false;
      })
      .addCase(getAllCities.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(getUsers.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload?.results;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      });
  },
});
export const { resetState, addQueryParams, resetStoreState, appendUser } =
  filtersSlice.actions;

export default filtersSlice.reducer;
