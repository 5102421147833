import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {
  uploadBackCNICImage,
  uploadFrontCNICImage,
} from "services/authServices";
import {
  getErrorData,
  getErrorMessage,
  getResponseData,
} from "utils/helpers/apiDataHelpers";
import setAuthState from "utils/helpers/setAuthState";
import { toast } from "utils/hooks/useToast";
import http from "utils/httpRequests/http";
import { setSessionExpiryDialogOpen } from "./uiSlice";

export const userLogin = createAsyncThunk(
  "auth/userLogin",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.post(`/login`, data);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      message && toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "auth/updateProfile",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const response = await http.patch(`/users/${state?.auth?.id}`, data);
      if (response.status === 200) {
        toast.success("Profile updated successfully");
      }
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const updateProfilePassword = createAsyncThunk(
  "auth/updateProfilePassword",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      const response = await http.put(`/users/${state?.auth?.id}`, data);
      toast.success("Password has been successfully changed");
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const verifyPhoneNumber = createAsyncThunk(
  "auth/verifyPhoneNumber",
  async (token, { rejectWithValue }) => {
    try {
      const response = await http.post(`/verify-phone`, {
        phoneToken: token,
      });
      toast.success("Phone number successfully verified");
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const verifyEmail = createAsyncThunk(
  "auth/verifyEmail",
  async (emailOTP, { rejectWithValue }) => {
    try {
      const response = await http.post(`/verify-email`, {
        emailCode: emailOTP,
      });
      toast.success("Email successfully verified");
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      if (message === "give the correct code") {
        return rejectWithValue("Invalid Verification Code");
      }
      return rejectWithValue(message);
    }
  }
);

export const changeProfilePassword = createAsyncThunk(
  "auth/changeProfilePassword",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.put(`/users/change-password`, data);
      toast.success("Password has been successfully changed");
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getAuthDetails = createAsyncThunk(
  "auth/getAuthDetails",
  async (_, { rejectWithValue }) => {
    try {
      const response = await http.get(`/sellerConfidentialDetail/current`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const updateAuthDetail = createAsyncThunk(
  "auth/updateAuthDetail",
  async (data, { getState, rejectWithValue }) => {
    try {
      const state = getState();
      let _data = {
        cnic_no: data.cnic_no,
        bankAccountTitle: data.bankAccountTitle,
        bankAccountNumber: data.bankAccountNumber,
        bankName: data.bankName,
      };
      const response = await http.patch(
        `/sellerConfidentialDetail/${state?.auth?.authData?.id}`,
        _data
      );

      if (typeof data.cnicFront === "object") {
        await uploadFrontCNICImage(
          response.data.id,
          data.cnicFront,
          "cnicFront"
        );
      }
      if (typeof data.cnicBack === "object") {
        await uploadBackCNICImage(response.data.id, data.cnicBack, "cnicBack");
      }
      if (response.status === 200) {
        toast.success("Account updated successfully");
      }

      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const updateEmail = createAsyncThunk(
  "auth/updateEmail",
  async (data, { getState, rejectWithValue }) => {
    try {
      const id = getState()?.auth?.id ?? "";
      const response = await http.patch(`/users/${id}`, data);
      toast.success("Email updated successfully");
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const updatePhoneNumber = createAsyncThunk(
  "auth/updatePhoneNumber",
  async (data, { getState, rejectWithValue }) => {
    try {
      const id = getState()?.auth?.id ?? "";
      const response = await http.patch(`/users/${id}`, data);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const refreshUserTokens = createAsyncThunk(
  "auth/refreshUserTokens",
  async (_, { getState, dispatch, rejectWithValue }) => {
    try {
      const tokens = getState()?.auth?.data?.tokens ?? {};
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/refresh-tokens`,
        {
          refreshToken: tokens?.refresh?.token,
        }
      );
      const responseData = getResponseData(response);
      localStorage.setItem("token", responseData?.access?.token);
      localStorage.setItem("refresh-token", responseData?.refresh?.token);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      const errorData = getErrorData(error);
      if (
        ["401", 401, "403", 403].includes(errorData?.code ?? "") &&
        errorData?.message === "jwt expired"
      ) {
        dispatch(setSessionExpiryDialogOpen(true));
      }
      return rejectWithValue(message);
    }
  }
);

const initialState = {
  id: "",
  token: "",
  fullName: "",
  userRole: "",
  isLoggedIn: false,
  data: {
    user: {},
    tokens: {
      access: {},
      refresh: {},
    },
  },
  authData: {},
  error: "",
  isPhoneOTPSent: false,
  isEmailOTPSent: false,
  loading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetErrorState: (state) => {
      state.error = null;
    },
    resetState: () => initialState,
    logout: () => {
      localStorage.clear();
      return initialState;
    },
    refreshTokens: (state, action) => {
      state.token = action?.payload?.access?.token;
      state.data.tokens.access = action?.payload?.access;
      state.data.tokens.refresh = action?.payload?.refresh;
    },
    setPhoneOTPStatus: (state, action) => {
      state.isPhoneOTPSent = action?.payload;
    },
    setEmailOTPStatus: (state, action) => {
      state.isEmailOTPSent = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userLogin.fulfilled, setAuthState)
      .addCase(userLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(updateProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.fullName = action?.payload?.fullname;
        state.data.user = action?.payload;
        state.error = null;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updateProfilePassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateProfilePassword.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateProfilePassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(changeProfilePassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(changeProfilePassword.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(changeProfilePassword.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAuthDetails.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getAuthDetails.fulfilled, (state, action) => {
        state.authData = action?.payload;
        state.loading = false;
      })
      .addCase(getAuthDetails.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(updateAuthDetail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAuthDetail.fulfilled, (state, action) => {
        state.loading = false;
        state.authData = action?.payload;
        state.error = null;
      })
      .addCase(updateAuthDetail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(verifyPhoneNumber.fulfilled, setAuthState)
      .addCase(verifyEmail.fulfilled, setAuthState)
      .addCase(updateEmail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateEmail.fulfilled, (state, action) => {
        state.loading = false;
        state.data.user = action?.payload;
        state.error = null;
      })
      .addCase(updateEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(updatePhoneNumber.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updatePhoneNumber.fulfilled, (state, action) => {
        state.loading = false;
        state.data.user = action?.payload;
        state.error = null;
      })
      .addCase(updatePhoneNumber.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(refreshUserTokens.fulfilled, (state, action) => {
        state.token = action?.payload?.access?.token;
        state.data.tokens.access = action?.payload?.access;
        state.data.tokens.refresh = action?.payload?.refresh;
      })
      .addCase(refreshUserTokens.rejected, (state, action) => {
        state.error = action?.payload;
      });
  },
});

export const {
  resetState,
  resetErrorState,
  logout,
  refreshTokens,
  setPhoneOTPStatus,
  setEmailOTPStatus,
} = authSlice.actions;

export default authSlice.reducer;
