import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getErrorMessage, getResponseData } from "utils/helpers/apiDataHelpers";
import { toast } from "utils/hooks/useToast";
import { http } from "utils/httpRequests/http";

export const getMasterTable = createAsyncThunk(
  "table/getMasterTable",
  async (data, { getState, rejectWithValue }) => {
    const { page, limit } = getState().table;
    try {
      const response = await http.get(`/settings`, {
        params: {
          page: data.page ?? page,
          limit: data.limit ?? limit,
          ...data,
        },
      });
      const responseData = getResponseData(response);
      responseData &&
        responseData?.results &&
        responseData?.results.forEach((item, index) => {
          const pageStart = (responseData?.page - 1) * responseData?.limit;
          item.serialNumber = pageStart + index + 1;
        });
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getTableById = createAsyncThunk(
  "table/getTableById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await http.get(`/settings/${id}`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const createMasterTable = createAsyncThunk(
  "table/createMasterTable",
  async (data = {}, { rejectWithValue }) => {
    try {
      const response = await http.post(`/settings`, data);
      toast.success("table created successfully");
      return getResponseData(response);
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const updateMasterTable = createAsyncThunk(
  "table/updateMasterTable",
  async (values = {}, { rejectWithValue }) => {
    const { id, updatedValues } = values;
    try {
      const response = await http.patch(`/settings/${id}`, updatedValues);
      toast.success("User updated successfully");
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const deleteMasterTable = createAsyncThunk(
  "table/deleteMasterTable",
  async (id, { getState, rejectWithValue }) => {
    try {
      let { results: table, page, limit } = getState().table;
      await http.delete(`/settings/${id}`);
      toast.success("Deleted successfully");
      let _table = table.filter((table) => table.id !== id);
      if (_table) {
        _table = _table.map((item, index) => {
          const pageStart = (page - 1) * limit;
          return {
            ...item,
            serialNumber: pageStart + index + 1,
          };
        });
        return _table;
      }
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

const initialState = {
  loading: false,
  results: [],
  user: null,
  page: 1,
  limit: 20,
  totalPages: 0,
  totalResults: 0,
  error: "",
};

const masterTableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {
    resetState: () => initialState,
    resetMasterTableState: (state) => {
      state.table = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMasterTable.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getMasterTable.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.results = action.payload?.results;
        state.page = action.payload?.page;
        state.limit = action.payload?.limit;
        state.totalResults = action.payload?.totalResults;
        state.totalPages = action.payload?.totalPages;
      })
      .addCase(getMasterTable.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(getTableById.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getTableById.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.table = action.payload;
      })
      .addCase(getTableById.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(createMasterTable.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(createMasterTable.fulfilled, (state) => {
        state.loading = false;
        state.error = "";
      })
      .addCase(createMasterTable.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(updateMasterTable.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(updateMasterTable.fulfilled, (state) => {
        state.loading = false;
        state.error = "";
      })
      .addCase(updateMasterTable.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(deleteMasterTable.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(deleteMasterTable.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.results = action.payload;
      })
      .addCase(deleteMasterTable.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      });
  },
});

// Action creators generated for each case reducer function
export const { resetState, resetMasterTableState } = masterTableSlice.actions;

// Exporting default reducer
export default masterTableSlice.reducer;
