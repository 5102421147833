import { memo } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import USER_ROLE from "utils/constants/userRole";

function ProtectedRoute() {
  const token = useSelector((state) => state.auth.token);
  const userRole = useSelector((state) => state.auth?.userRole);
  const phoneNumber = useSelector(
    (state) => state.auth.data?.user?.phone ?? ""
  );

  if (token && !phoneNumber) {
    return <Navigate to="/phone-number" />;
  }

  if (userRole === "") {
    return <Navigate to="/login" />;
  }

  if (
    userRole === USER_ROLE.SUPER_ADMIN ||
    userRole === USER_ROLE.ADMIN ||
    userRole === USER_ROLE.GOLD_PARTNER ||
    userRole === USER_ROLE.SALE_AGENT
  ) {
    return <Outlet />;
  }

  return <Navigate to="/login" />;
}

export default memo(ProtectedRoute);
