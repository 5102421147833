import Stack from "@mui/material/Stack";
import PageHeader from "components/PageHeader";
import Actions from "components/Preferences/Actions";
import PreferencesDataGrid from "components/Preferences/DataGrid";
import PreferencesFilters from "components/Preferences/Filters";
import PreferencesForm from "components/Preferences/PreferenceForm/PreferencesForm";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetPreferencesState } from "store/slices/preferencesSlice";
import USER_ROLE from "utils/constants/userRole";
function Preferences({ create, update }) {
  const dispatch = useDispatch();
  const loggedUserRole = useSelector((state) => state.auth.userRole);

  useEffect(() => {
    return () => dispatch(resetPreferencesState());
  }, [dispatch]);

  if (create || update) return <PreferencesForm />;

  return (
    <Stack direction="column" spacing={3}>
      <PageHeader title="Preferences" />
      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent={{ xs: "center", md: "space-between" }}
        alignItems={{ xs: "flex-start", md: "center" }}
        spacing={3}
      >
        {loggedUserRole === USER_ROLE.SUPER_ADMIN && <Actions />}
        <PreferencesFilters />
      </Stack>
      <PreferencesDataGrid />
    </Stack>
  );
}

Preferences.propTypes = {
  create: PropTypes.bool,
  update: PropTypes.bool,
};

Preferences.defaultProps = {
  create: false,
  update: false,
};

export default Preferences;
