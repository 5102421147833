import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { RecaptchaVerifier, getAuth } from "firebase/auth";
import PropTypes from "prop-types";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactCodeInput from "react-verification-code-input";
import { sendPhoneOTP, verifyPhoneOTP } from "services/authServices";
import { updatePhoneNumber } from "store/slices/authSlice";
import StyledMuiCard from "utils/helpers/StyledMuiCard";
import formatPhoneNumber from "utils/helpers/formatPhoneNumber";
import { firebaseApp } from "utils/lib/FirebaseAppSettings";

const auth = getAuth(firebaseApp);

function PhoneVerification({
  values,
  isSubmitting,
  isLoading,
  setIsSubmitting,
  error,
  setError,
}) {
  const dispatch = useDispatch();
  const isPhoneOTPSent = useSelector(
    (state) => state.auth?.isPhoneOTPSent ?? false
  );

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneOTP, setPhoneOTP] = useState("");
  const [time, setTime] = useState("");
  const navigate = useNavigate();

  const initializeRecaptchaVerifier = useCallback(() => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
        },
        auth
      );
    }
  }, []);

  const sendOTP = useCallback(() => {
    sendPhoneOTP(auth, phoneNumber, setError, setIsSubmitting);
  }, [phoneNumber, setError, setIsSubmitting]);

  const resendOTP = useCallback(() => {
    initializeRecaptchaVerifier();
    setTime(120);
    setPhoneOTP("");
    setError("");
    sendOTP();
  }, [initializeRecaptchaVerifier, sendOTP, setError]);

  const handlePhoneCodeVerification = useCallback(
    async (e) => {
      e.preventDefault();
      const invoicesId = values?.invoicesId;
      const phone = values?.phone;
      verifyPhoneOTP(
        phoneOTP,
        setError,
        setIsSubmitting,
        invoicesId,
        phone,
        navigate
      );
    },
    [
      navigate,
      phoneOTP,
      setError,
      setIsSubmitting,
      values?.invoicesId,
      values?.phone,
    ]
  );

  useEffect(() => {
    initializeRecaptchaVerifier();
    return () => {
      if (window?.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
        window.recaptchaVerifier = null;
      }
    };
  }, [initializeRecaptchaVerifier]);

  useEffect(() => {
    if (phoneNumber && isPhoneOTPSent && isSubmitting) {
      const id = setTimeout(() => {
        setTime(120);
        sendOTP();
      }, 1000);
      return () => id && clearTimeout(id);
    }
  }, [phoneNumber, isPhoneOTPSent, sendOTP, isSubmitting]);

  useEffect(() => {
    let interval;
    if (time > 0) {
      interval = setInterval(() => {
        setTime((time) => time - 1);
      }, 1000);
    }
    return () => interval && clearInterval(interval);
  }, [time]);

  useEffect(() => {
    if (values?.phone && /^(03\d{9})$/.test(values?.phone)) {
      const formattedPhoneNumber = formatPhoneNumber(values?.phone);
      setPhoneNumber(formattedPhoneNumber);
      dispatch(updatePhoneNumber({ phone: formattedPhoneNumber }));
    } else if (values?.phone) {
      setPhoneNumber(values?.phone);
    }
  }, [dispatch, values?.phone]);

  if (isLoading) {
    return (
      <StyledMuiCard
        title="Verify Phone Number"
        subTitle="Please verify you phone number against valid otp , sent to your phone."
      >
        <CardContent>
          <Stack
            direction="column"
            alignContent="center"
            justifyContent="center"
            minHeight="50vh"
            maxWidth={{
              sm: "250px",
              md: "450px",
              lg: "400px",
              xl: "535px",
            }}
            // m="auto"
            spacing={{ xs: 1.75, md: 2.4, lg: 3, xl: 6 }}
          >
            <form onSubmit={handlePhoneCodeVerification}>
              <Stack direction="column" gap={0.5}>
                <Box>
                  <ReactCodeInput
                    type="number"
                    onChange={setPhoneOTP}
                    className="code-input"
                  />
                  <Typography
                    variant="body1"
                    color="text.secondary"
                    fontSize={{ lg: "1.15rem" }}
                  >
                    {`Please enter the verification code sent on ${phoneNumber} `}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Typography
                    color="text.secondary"
                    fontSize={{ lg: "1.15rem" }}
                  >
                    {`Didn’t receive code?`}
                  </Typography>
                  <Button
                    variant="text"
                    onClick={time > 0 ? null : resendOTP}
                    disabled={time > 0}
                    sx={{
                      fontWeight: "bold",
                      textTransform: "none",
                      fontSize: { lg: "1.15rem" },
                    }}
                  >
                    Resend Code
                    {time > 0 && ` in ${time} seconds`}
                  </Button>
                </Box>
                {error && (
                  <Typography color="error" fontSize={{ lg: "1.15rem" }} mb={1}>
                    {error}
                  </Typography>
                )}
                <Button
                  fullWidth
                  disabled={isSubmitting || phoneOTP.length < 6}
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{ minHeight: { md: 44, xl: 56 } }}
                >
                  Redeem Now
                </Button>
              </Stack>
            </form>
          </Stack>
        </CardContent>
      </StyledMuiCard>
    );
  }
}
export default memo(PhoneVerification);

PhoneVerification.propTypes = {
  phone: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  setIsPhoneNumberVerified: PropTypes.func,
  setIsSubmitting: PropTypes.func,
  error: PropTypes.string,
  setError: PropTypes.func,
  isLoading: PropTypes.bool,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
};

PhoneVerification.defaultProps = {
  phone: false,
  isSubmitting: false,
  setIsPhoneNumberVerified: false,
  error: "",
  isLoading: false,
};
