import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SettingSideTabs from "components/Settings/index.jsx";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import { memo } from "react";

function Settings() {
  return (
    <Box py={2} px={1}>
      <Box display="flex" alignItems="center">
        <Typography
          variant="h4"
          sx={{
            fontWeight: "600",
            fontSize: { md: "1rem", lg: "1.5rem" },
            paddingRight: "1rem",
          }}
        >
          SETTINGS
        </Typography>
        <Box paddingTop={2} paddingBottom={1}>
          <CustomBreadcrumbs />
        </Box>
      </Box>
      <SettingSideTabs />
    </Box>
  );
}

export default memo(Settings);
