import { ACTIVE_COLOR } from "styles/colors";

export default function statusBadges(status) {
  let color = "";
  let bgColor = "";
  switch (status) {
    case StatusOptions.ACTIVE:
      color = ACTIVE_COLOR;
      bgColor = "#ccf1dc";
      break;
    case StatusOptions.INACTIVE_COLOR:
      color = "#ff0000";
      bgColor = "#ffcccc";
      break;
    case StatusOptions.PENDING:
      color = "#fff";
      bgColor = "#00000057";
      break;
    case StatusOptions.PAID:
      color = ACTIVE_COLOR;
      bgColor = "#ccf1dc";
      break;
    case StatusOptions.NEW:
      color = "#ff8319";
      bgColor = "#fff1dc";
      break;
    case StatusOptions.REPLAYED:
      color = "#3498db";
      bgColor = "#110091dc";
      break;
    case StatusOptions.REDEEMED:
      color = ACTIVE_COLOR;
      bgColor = "#ccf1dc";
      break;
    case StatusOptions.EXPIRED:
      color = "#ff0000";
      bgColor = "#ffcccc";
      break;

    default:
      break;
  }
  return { color, bgColor };
}

const StatusOptions = {
  ACTIVE: "true",
  INACTIVE: "false",
  PENDING: "pending",
  PAID: "paid",
  NEW: "new",
  REPLAYED: "replayed",
  REDEEMED: "redeemed",
  EXPIRED: "expired",
};
