import PropTypes from "prop-types";
import { memo } from "react";

const RoleTitle = ({ role }) => {
  const getRoleTitle = (role) => {
    const roleTitles = {
      goldPartner: "Partner Panel",
      superAdmin: "Super Admin Panel",
      admin: "Admin Panel",
      saleAgent: "Agent Panel",
    };
    return roleTitles[role] || "";
  };

  return (
    <div>
      <p> {getRoleTitle(role)}</p>
    </div>
  );
};
RoleTitle.propTypes = {
  role: PropTypes.string.isRequired,
};
export default memo(RoleTitle);
