import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import queryString from "query-string";
import { memo, useCallback, useLayoutEffect, useState } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import REDEMPTION_FILTER from "utils/constants/redemptionFilter";

function Filters() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = queryString.parse(search);
  const [value, setValue] = useState("all");

  const handleChange = useCallback(
    (_, value) => {
      setValue(value);
      // Necessary for performance
      // eslint-disable-next-line no-unused-vars
      const { donationStatus, ...rest } = query;
      let params = { ...rest };
      switch (value) {
        case REDEMPTION_FILTER.PENDING:
          params.donationStatus = REDEMPTION_FILTER.PENDING;
          break;
        case REDEMPTION_FILTER.DONATED:
          params.donationStatus = REDEMPTION_FILTER.DONATED;
          break;
        default:
          break;
      }
      navigate({
        pathname: "/redemptions",
        search: createSearchParams(params).toString(),
      });
    },
    [navigate, query]
  );

  useLayoutEffect(() => {
    if (query.donationStatus) {
      setValue(query.donationStatus);
    }
  }, [query.donationStatus]);

  return (
    <Box sx={containerStyles}>
      <Box
        sx={{
          backgroundColor: "white",
          marginBottom: { xs: 2, md: 0 },
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="redemption status filters"
        >
          <Tab label="All Redemption" value="all" />
          <Tab label="Pending" value={REDEMPTION_FILTER.PENDING} />
          <Tab label="DONATED" value={REDEMPTION_FILTER.DONATED} />
        </Tabs>
      </Box>
    </Box>
  );
}

export default memo(Filters);

const containerStyles = {
  display: { xs: "grid", md: "flex" },
  justifyContent: "space-between",
  alignItems: "baseline",
  "& .styled-button": {
    borderRadius: "1px",
    padding: "10px",
  },
  "& .MuiOutlinedInput-root": { borderRadius: "20px" },
  "& .MuiTab-root": { textTransform: "capitalize" },
};
