import { memo } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

function AuthPhoneNumberRoute() {
  const token = useSelector((state) => state.auth.token);
  const phoneNumber = useSelector(
    (state) => state.auth.data?.user?.phone ?? ""
  );

  if (token && phoneNumber) {
    return <Navigate to="/" />;
  } else if (token && !phoneNumber) {
    return <Outlet />;
  }
  return <Navigate to="/login" />;
}

export default memo(AuthPhoneNumberRoute);
