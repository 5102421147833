import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import PageHeader from "components/PageHeader";
import Actions from "components/Redemption/Actions";
import DataGrid from "components/Redemption/DataGrid";
import DateFilters from "components/Redemption/DateFilters";
import Filters from "components/Redemption/Filters";
import RedemptionForm from "components/Redemption/RedemptionForm";
import PropTypes from "prop-types";
import { memo } from "react";
import { useSelector } from "react-redux";

const Redemption = ({ create }) => {
  const userRole = useSelector((state) => state.auth?.userRole);

  if (create) {
    return <RedemptionForm />;
  }

  return (
    <Stack direction="column" spacing={3}>
      <PageHeader title="Redemption" />
      <Box
        display="flex"
        justifyContent={userRole === "goldPartner" ? "space-between" : "end"}
      >
        {userRole === "goldPartner" && <Actions />}
        <DateFilters />
      </Box>
      <Filters />
      <DataGrid />
    </Stack>
  );
};

export default memo(Redemption);

Redemption.propTypes = {
  create: PropTypes.bool,
};

Redemption.defaultProps = {
  create: false,
};
