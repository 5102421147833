import { useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";
import AuthPageEllipse from "assets/icons/AuthPageEllipse.svg";
import GoldRushLogo from "assets/icons/GoldRushLogo.svg";
import AuthSideContent from "components/authentication/AuthSideContent";
import ResetPasswordForm from "components/authentication/ResetPasswordForm";

function ResetPassword() {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isMedium = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isLarge = useMediaQuery((theme) => theme.breakpoints.down("xl"));
  return (
    <Grid container>
      <Grid item xs={6} position="relative" backgroundColor="#BD943E">
        <img
          src={GoldRushLogo}
          alt="BazaarGhar Logo"
          height="auto"
          width={
            isSmall ? "80px" : isMedium ? "100px" : isLarge ? "125px" : "176px"
          }
          style={{
            position: "absolute",
            top: isMedium ? "1rem" : "2rem",
            left: isMedium ? "1rem" : "2rem",
          }}
        />
        <AuthSideContent />
      </Grid>
      <Grid item xs={6} position="relative" overflow="hidden">
        <img
          src={AuthPageEllipse}
          alt="Ellipse"
          height={isMedium ? 175 : isLarge ? 225 : 300}
          width={isMedium ? 175 : isLarge ? 225 : 300}
          style={{
            position: "absolute",
            top: isMedium ? -100 : isLarge ? -115 : -150,
            right: isMedium ? -100 : isLarge ? -115 : -150,
          }}
        />
        <ResetPasswordForm />
      </Grid>
    </Grid>
  );
}

export default ResetPassword;
