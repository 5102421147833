import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import WritingIcon from "assets/icons/WritingIcon.svg";
import deleteIcon from "assets/icons/deleteIcon.svg";
import ConfirmDelete from "components/Dialog/ConfirmDelete";
import StyledDataGrid from "components/StyledGrid";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  deletePreference,
  getPreferences,
} from "store/slices/preferencesSlice";
import {
  setConfirmDeletionDialogOpen,
  setDeleteId,
} from "store/slices/uiSlice";
import USER_ROLE from "utils/constants/userRole";
import { formatCurrencyNumber } from "utils/helpers/formatCurrency";
import useQueryParams from "utils/hooks/useQueryParams";

function PreferencesDataGrid() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const loading = useSelector((state) => state.preferences.loading.preferences);
  const { results = [], totalPages = 0 } = useSelector(
    (state) => state.preferences.preferences
  );
  const loggedUserRole = useSelector((state) => state.auth?.userRole ?? "");

  const handleClickOpen = useCallback(
    (preferenceId) => {
      dispatch(setConfirmDeletionDialogOpen());
      dispatch(setDeleteId(preferenceId));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(getPreferences(queryParams));
  }, [dispatch, queryParams]);

  const columns = useMemo(
    () => [
      {
        field: "serialNumber",
        headerName: "Sr",
        minWidth: 50,
        flex: 0.25,
        filterable: false,
      },
      {
        field: "userName",
        headerName: "Username",
        minWidth: 200,
        flex: 1,
        editable: true,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Typography textTransform="capitalize">
            {params?.row?.userName}
          </Typography>
        ),
      },
      {
        field: "type",
        headerName: "Type",
        minWidth: 100,
        flex: 0.5,
        editable: false,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Typography textTransform="capitalize">
            {params?.row?.type}
          </Typography>
        ),
      },
      {
        field: "currency",
        headerName: "Currency",
        minWidth: 100,
        flex: 0.75,
        editable: false,
        sortable: false,
        filterable: false,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => (
          <Typography textTransform="uppercase">
            {params?.row?.currency}
          </Typography>
        ),
      },
      {
        field: "amount",
        headerName: "Amount",
        minWidth: 150,
        flex: 0.75,
        headerAlign: "right",
        align: "right",
        editable: false,
        sortable: false,
        filterable: false,
        renderHeader: () => (
          <Typography variant="h6" pr={2}>
            Amount
          </Typography>
        ),
        renderCell: (params) => (
          <Typography textTransform="capitalize" pr={2}>
            {formatCurrencyNumber(params?.row?.amount || 0)}
          </Typography>
        ),
      },
      {
        field: "actions",
        headerName: "Actions",
        headerAlign: "right",
        align: "right",
        minWidth: 150,
        flex: 1,
        disableColumnMenu: true,
        hide: loggedUserRole !== USER_ROLE.SUPER_ADMIN,
        sortable: false,
        renderHeader: () => (
          <Typography variant="h6" pr={2}>
            Actions
          </Typography>
        ),
        renderCell: (params) => (
          <Box display="flex" pr={1}>
            <IconButton
              size="small"
              onClick={() => navigate(`${params.id}/update`)}
            >
              <img src={WritingIcon} alt="edit" />
            </IconButton>

            <IconButton
              size="small"
              onClick={() => handleClickOpen(params?.id)}
            >
              <img src={deleteIcon} alt="edit" />
            </IconButton>
          </Box>
        ),
      },
    ],
    [handleClickOpen, loggedUserRole, navigate]
  );
  return (
    <>
      <StyledDataGrid
        rows={results}
        columns={columns}
        loading={loading}
        totalPages={totalPages}
      />
      <ConfirmDelete deleteAction={deletePreference} />
    </>
  );
}

export default memo(PreferencesDataGrid);
