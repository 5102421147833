import Stack from "@mui/material/Stack";
import PageHeader from "components/PageHeader";
import Actions from "components/Users/Actions";
import DataGrid from "components/Users/DataGrid";
import Filters from "components/Users/Filters";
import UserForm from "components/Users/UserForm";
import DateFilters from "components/Users/UserForm/DateFilters";
import PropTypes from "prop-types";
import { memo } from "react";

const Users = ({ create, update }) => {
  if (create || update) {
    return <UserForm />;
  }

  return (
    <Stack direction="column" spacing={3}>
      <PageHeader title="Users" />
      <Stack direction="row" justifyContent="space-between">
        <Actions />
        <DateFilters />
      </Stack>
      <Filters />
      <DataGrid />
    </Stack>
  );
};

export default memo(Users);

Users.propTypes = {
  create: PropTypes.bool,
  update: PropTypes.bool,
};

Users.defaultProps = {
  create: false,
  update: false,
};
