import Box from "@mui/material/Box";
import DataGridSearch from "components/DataGridSearch";
import { memo } from "react";

function PreferencesFilters() {
  return (
    <Box display="flex" justifyContent={{ xs: "flex-start", md: "flex-end" }}>
      <DataGridSearch
        searchKeyParam="name"
        searchKeyParamValue="userName"
        searchQueryParam="value"
      />
    </Box>
  );
}

export default memo(PreferencesFilters);
