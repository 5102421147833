import Box from "@mui/material/Box";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import useMediaQuery from "@mui/material/useMediaQuery";
import { DataGrid } from "@mui/x-data-grid";
import propTypes from "prop-types";
import { memo, useCallback } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import useQueryParams from "utils/hooks/useQueryParams";

const StyledDataGrid = ({
  rows,
  columns,
  loading,
  totalPages,
  customRowId,
  onSelectionModelChange,
  selectionModel,
  isRowSelectable,
}) => {
  const params = useQueryParams();
  const navigate = useNavigate();
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const handlePagination = useCallback(
    (_, newPage) => {
      window.scrollTo(0, 0);
      const _params = { ...params };
      _params.page = newPage;
      _params.limit = params?.limit ?? 20;
      navigate({
        search: createSearchParams(_params).toString(),
      });
    },
    [navigate, params]
  );

  const customPagination = useCallback(() => {
    const _page = params.page ? params.page : 1;
    return (
      <Pagination
        variant="outlined"
        color="primary"
        sx={{ marginY: 4 }}
        size={isTablet ? "small" : "large"}
        count={totalPages}
        page={_page}
        disabled={totalPages === 1}
        onChange={handlePagination}
      />
    );
  }, [params.page, isTablet, totalPages, handlePagination]);

  const customNoRecordFound = useCallback(() => {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        No record found
      </Stack>
    );
  }, []);

  return (
    <Box sx={containerStyles}>
      <DataGrid
        container
        rows={rows}
        columns={columns}
        loading={loading}
        onSelectionModelChange={onSelectionModelChange}
        selectionModel={selectionModel}
        pagination
        autoHeight
        disableSelectionOnClick
        checkboxSelection
        disableColumnMenu
        rowsPerPageOptions={[10, 15, 20]}
        components={{
          Pagination: customPagination,
          NoRowsOverlay: customNoRecordFound,
        }}
        isCellEditable={() => false}
        getRowHeight={() => "auto"}
        getRowSpacing={(params) => ({
          top: params.isFirstVisible ? 0 : 6,
          bottom: params.isLastVisible ? 0 : 6,
        })}
        getRowId={(row) => {
          if (customRowId) {
            return row[customRowId];
          }
          return row.id;
        }}
        {...(isRowSelectable && { isRowSelectable })}
        keepNonExistentRowsSelected
        sx={{
          "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus":
            {
              outline: "none",
            },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "1.25rem",
          },
        }}
      />
    </Box>
  );
};

export default memo(StyledDataGrid);

StyledDataGrid.propTypes = {
  rows: propTypes.oneOfType([propTypes.object, propTypes.array]).isRequired,
  columns: propTypes.oneOfType([propTypes.object, propTypes.array]).isRequired,
  loading: propTypes.bool,
  totalPages: propTypes.number,
  handlePagination: propTypes.func,
  customRowId: propTypes.string,
  onSelectionModelChange: propTypes.func,
  selectionModel: propTypes.oneOfType([propTypes.object, propTypes.array]),
  isRowSelectable: propTypes.func,
};

const containerStyles = {
  width: "100%",
  "& .MuiDataGrid-root": { border: "none", fontSize: "1rem" },
  "& .MuiPaginationItem-root.Mui-selected": {
    border: "none",
  },
  "& .MuiPaginationItem-outlinedPrimary": {
    border: "none",
  },
  "& .MuiPaginationItem-previousNext": {
    backgroundColor: "#078ecf1c",
  },
  "& .MuiDataGrid-cell": {
    color: "primary",
    borderBottom: "0px !important",
  },
  "& .MuiDataGrid-row": {
    backgroundColor: "#fff",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  },
  "& .MuiDataGrid-row:hover": {
    backgroundColor: "#fff !important",
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
  },
  "& .MuiDataGrid-columnHeaders": {
    borderBottom: "0px !important",
  },
  "& .MuiDataGrid-columnHeader:focus": {
    outline: "none !important",
  },
  "& .MuiDataGrid-columnSeparator": {
    visibility: "hidden",
  },
  "& .MuiDataGrid-cell:focus-within": {
    outline: "none !important",
  },
};
