import { initializeApp } from "firebase/app";

export const firebaseConfig = {
  apiKey: "AIzaSyDEK_-BqRAfNnogunJgpqGhCv24isAUGkg",
  authDomain: "goldrush-main.firebaseapp.com",
  projectId: "goldrush-main",
  storageBucket: "goldrush-main.appspot.com",
  messagingSenderId: "907019658195",
  appId: "1:907019658195:web:114e3719e8868b6f9e4526",
  measurementId: "G-D77V9NQ0VD",
};

export const firebaseApp = initializeApp(firebaseConfig);
