import Dashboard from "assets/icons/Dashboard.svg";
import Notifications from "assets/icons/Notifications.svg";
import Orders from "assets/icons/Orders.svg";
import Users from "assets/icons/Users.svg";
import USER_ROLE from "./userRole";
const SideBarListItems = [
  {
    roles: [
      USER_ROLE.ADMIN,
      USER_ROLE.SUPER_ADMIN,
      USER_ROLE.SALE_AGENT,
      USER_ROLE.GOLD_PARTNER,
    ],
    title: "Dashboard",
    path: "/dashboard",
    icon: Dashboard,
  },
  {
    roles: [USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN],
    title: "Users",
    path: "/users",
    icon: Users,
    children: [
      {
        roles: [USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN],
        title: "Preferences",
        path: "/preferences",
      },
    ],
  },

  {
    roles: [USER_ROLE.ADMIN, USER_ROLE.SUPER_ADMIN],
    title: "Master Table",
    path: "/master-table",
    icon: Orders,
  },
  {
    roles: [
      USER_ROLE.ADMIN,
      USER_ROLE.SUPER_ADMIN,
      USER_ROLE.SALE_AGENT,
      USER_ROLE.GOLD_PARTNER,
    ],
    title: "Invoices",
    path: "/invoices",
    icon: Notifications,
  },
  {
    roles: [
      USER_ROLE.ADMIN,
      USER_ROLE.SUPER_ADMIN,
      USER_ROLE.SALE_AGENT,
      USER_ROLE.GOLD_PARTNER,
    ],
    title: "Redemptions",
    path: "/redemptions",
    icon: Notifications,
  },
];

export default SideBarListItems;
