import { CalendarToday } from "@mui/icons-material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import propTypes from "prop-types";
import { memo, useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { FILTERS_BACKGROUND_COLOR, FILTER_TEXT_COLOR } from "styles/colors";

const DateRangeFilter = ({ onChange }) => {
  const [open, setOpen] = useState(false);
  const [range, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const handleClose = () => setOpen(false);

  const handleApply = () => {
    onChange(range[0]);
    handleClose();
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        endIcon={<CalendarToday />}
        variant="outlined"
        sx={{
          height: "44px",
          color: FILTER_TEXT_COLOR,
          minWidth: 170,
          maxWidth: 180,
          textTransform: "none",
          fontSize: "1rem",
          background: FILTERS_BACKGROUND_COLOR,
        }}
      >
        Select by Date
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={modalStyle}>
          <DateRangePicker
            onChange={(item) => setState([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            ranges={range}
            maxDate={new Date()}
            direction="vertical"
          />
          <Box alignSelf="end">
            <Button
              onClick={handleClose}
              variant="outlined"
              className="AddButtons"
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleApply}
              variant="contained"
              className="AddButtons"
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default memo(DateRangeFilter);

DateRangeFilter.propTypes = {
  onChange: propTypes.func,
};

const modalStyle = {
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  // "& .MuiTypography-colorTextSecondary": {
  //   color: "#BD943E !important",
  // },
  // "& .MuiTypography-colorTextPrimary": {
  //   color: "#BD943E !important",
  // },
  // "& .rdrStaticRangeSelected": { color: "#BD943E !important" },
  // "& .rdrStartEdge": { color: "#BD943E !important" },
  // "& .rdrEndEdge": { color: "#BD943E !important" },
  // "& .rdrInRange": { color: "#BD943E !important" },
  // "& .rdrDayHovered": { color: "#BD943E !important" },
  // "& .rdrDay .rdrDayHovered": { color: "#BD943E !important" },
  "& .AddButtons": {
    height: "44px",
    textTransform: "none",
    fontSize: {
      xs: "0.8rem",
      sm: "1rem",
    },
    cursor: "pointer",
  },
};
