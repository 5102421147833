import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import CurrencyFormatter from "components/CurrencyFormatter";
import StyledDataGrid from "components/StyledGrid";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getInvoices,
  setSelectedRowsData,
  updateInvoicesStatus,
} from "store/slices/invoicesSlice";
import statusBadges from "utils/helpers/statusBadge";
import useQueryParams from "utils/hooks/useQueryParams";

export default function DataGrid() {
  const params = useQueryParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    results: invoices,
    loading,
    totalPages,
  } = useSelector((state) => state?.invoices);
  const selectedRowsData = useSelector(
    (state) => state?.invoices?.selectedRowsData
  );
  const userRole = useSelector((state) => state.auth?.userRole);

  const fetchTable = useCallback(() => {
    dispatch(getInvoices(params));
  }, [dispatch, params]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      fetchTable();
    }, 500);
    return () => clearTimeout(timeOut);
  }, [dispatch, fetchTable]);

  const onSelectionModelChange = useCallback(
    (ids) => {
      dispatch(setSelectedRowsData(ids));
    },
    [dispatch]
  );
  const handleInvoices = useCallback(() => {
    dispatch(updateInvoicesStatus({ selectedRowsData, navigate }));
  }, [dispatch, navigate, selectedRowsData]);

  const columns = useMemo(
    () => [
      {
        field: "invoiceNumber",
        headerName: "Number",
        minWidth: 110,
        flex: 0.4,
        editable: true,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Typography>{params?.row?.invoiceNumber}</Typography>
        ),
      },
      {
        field: "invoiceId",
        headerName: "Id",
        minWidth: 120,
        flex: 0.4,
        editable: true,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Typography>{params?.row?.invoiceId}</Typography>
        ),
      },
      {
        field: "voucherNumber",
        headerName: "Voucher #",
        minWidth: 120,
        flex: 0.5,
        editable: true,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Typography>{params?.row?.voucherNumber}</Typography>
        ),
      },
      {
        field: "invoiceType",
        headerName: "Type",
        minWidth: 100,
        flex: 0.4,
        editable: false,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Typography textTransform="capitalize">
            {params?.row?.invoiceType}
          </Typography>
        ),
      },
      {
        field: "amount",
        headerName: "Amount",
        minWidth: 120,
        flex: 0.4,
        headerAlign: "right",
        align: "right",
        sortable: false,
        editable: true,
        renderHeader: () => (
          <Typography variant="h6" pr={3}>
            Amount
          </Typography>
        ),
        renderCell: (params) => (
          <CurrencyFormatter number={params?.row?.amount ?? 0} pr={3} />
        ),
      },
      {
        field: "amountInWords",
        headerName: "Amt In Words",
        minWidth: 140,
        flex: 0.5,
        editable: false,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Typography textTransform="capitalize">
            {params?.row?.amountInWords}
          </Typography>
        ),
      },
      {
        field: "phone",
        headerName: "Phone",
        minWidth: 160,
        flex: 0.9,
        sortable: false,
        editable: true,
        align: "center",
        headerAlign: "center",
        renderCell: (params) => <Typography>{params?.row?.phone}</Typography>,
      },
      {
        field: "email",
        headerName: "Email",
        minWidth: 210,
        flex: 1,
        sortable: false,
        editable: true,
        renderCell: (params) => <Typography>{params?.row?.email}</Typography>,
      },
      {
        field: "createdAt",
        headerName: "Date & Time",
        minWidth: 220,
        flex: 1,
        renderCell: (params) => (
          <Typography>
            {dayjs(params?.row?.createdAt).format("DD MMM, YYYY [at] hh:mm A")}
          </Typography>
        ),
      },
      {
        field: "invoiceStatus",
        headerName: "Invoice",
        minWidth: 100,
        flex: 0.2,
        editable: true,
        sortable: false,
        filterable: false,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => (
          <Chip
            sx={{
              color: statusBadges(params?.row?.invoiceStatus).color,
              bgcolor: statusBadges(params?.row?.invoiceStatus).bgColor,
              borderRadius: "5px",
              height: "26px",
              textTransform: "capitalize",
            }}
            variant="contained"
            label={params?.row?.invoiceStatus}
          />
        ),
      },
      {
        field: "paidStatus",
        headerName: "Paid",
        minWidth: 100,
        flex: 0.2,
        editable: true,
        sortable: false,
        filterable: false,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => (
          <Chip
            sx={{
              color: statusBadges(params?.row?.paidStatus).color,
              bgcolor: statusBadges(params?.row?.paidStatus).bgColor,
              borderRadius: "5px",
              height: "26px",
              textTransform: "capitalize",
            }}
            variant="contained"
            label={params?.row?.paidStatus}
          />
        ),
      },
    ],
    []
  );
  return (
    <>
      {invoices ? (
        <>
          {selectedRowsData.length > 0 &&
            (userRole === "admin" || userRole === "superAdmin") && (
              <Button
                size="large"
                variant="contained"
                py={1}
                sx={{ color: "#fff", width: "fit-content" }}
                onClick={handleInvoices}
              >
                Update Status
              </Button>
            )}
          <StyledDataGrid
            rows={invoices}
            columns={columns}
            loading={loading}
            totalPages={totalPages}
            onSelectionModelChange={onSelectionModelChange}
            selectionModel={selectedRowsData}
            isRowSelectable={({ row }) => row.paidStatus !== "paid"}
          />
        </>
      ) : (
        <Typography display="flex" justifyContent="center" mt={4}>
          No record found
        </Typography>
      )}
    </>
  );
}
