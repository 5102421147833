import Autocomplete from "@mui/material/Autocomplete";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import MUIStyledTextField from "components/MUIStyledComponents/MUIStyledTextField";
import PropTypes from "prop-types";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "store/slices/filtersSlice";
import StyledMuiCard from "utils/helpers/StyledMuiCard";
import useDebounce from "utils/hooks/useDebounceValue";

function UserInformation({ formik }) {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.filters.users ?? []);
  const loading = useSelector((state) => state.filters?.loading);
  const [searchValue, setSearchValue] = useState("");
  const [option, setOption] = useState(null);
  const deferredValue = useDebounce(searchValue, 700);

  useEffect(() => {
    const data = {};
    if (deferredValue) {
      data.fullname = deferredValue;
    }

    const id = setTimeout(() => {
      dispatch(getUsers(data));
    }, 500);

    return () => clearTimeout(id);
  }, [deferredValue, dispatch]);

  useEffect(() => {
    if (users?.length && formik.values.user) {
      const user = users.find((user) => user?.id === formik.values.user);
      setOption(user);
    } else if (users?.length) {
      setOption(null);
    }
  }, [formik.values.user, users]);

  useEffect(() => {
    if (
      formik.values.user &&
      !users.find((user) => user?.id === formik.values?.user)
    ) {
      dispatch(
        getUsers({
          fullname: formik.values.userName,
        })
      );
    }
  }, [dispatch, formik.values?.user, formik.values?.userName, loading, users]);

  return (
    <StyledMuiCard title="User Information" subTitle="Enter the user name">
      <CardContent>
        <Stack direction="column" gap={2} px={2}>
          <Autocomplete
            options={users}
            value={option}
            loading={loading}
            onChange={(_, option) => {
              formik.setFieldValue("user", option?.id ?? "");
            }}
            inputValue={searchValue}
            onInputChange={(_, value) => {
              setSearchValue(value);
            }}
            onBlur={formik.handleBlur}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            getOptionLabel={(option) => option?.fullname}
            renderInput={(params) => (
              <MUIStyledTextField
                fullWidth
                {...params}
                label="User Name"
                name="user"
                error={Boolean(formik.touched.user && formik.errors.user)}
                helperText={
                  formik.touched.user && !!formik.errors.user
                    ? formik.errors.user
                    : "Please enter the user name"
                }
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {option.fullname}
              </li>
            )}
          />
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}

UserInformation.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(UserInformation, arePropsEqual);

function arePropsEqual(prevProps, nextProps) {
  return (
    prevProps.formik.values.user === nextProps.formik.values.user &&
    prevProps.formik.touched.user === nextProps.formik.touched.user &&
    prevProps.formik.errors.user === nextProps.formik.errors.user &&
    prevProps.formik.values.userName === nextProps.formik.values.userName &&
    prevProps.formik.touched.userName === nextProps.formik.touched.userName &&
    prevProps.formik.errors.userName === nextProps.formik.errors.userName
  );
}
