import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "store/slices/authSlice";
import dashboardReducer from "store/slices/dashboardSlice";
import filtersReducer from "store/slices/filtersSlice";
import invoicesReducer from "store/slices/invoicesSlice";
import masterTableReducer from "store/slices/masterTableSlice";
import uiReducer from "store/slices/uiSlice";
import usersReducer from "store/slices/usersSlice";
import preferencesReducer from "./slices/preferencesSlice";
import redemptionReducer from "./slices/redemptionSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  UI: uiReducer,
  dashboard: dashboardReducer,
  invoices: invoicesReducer,
  redemption: redemptionReducer,
  filters: filtersReducer,
  users: usersReducer,
  table: masterTableReducer,
  preferences: preferencesReducer,
});

export default rootReducer;
