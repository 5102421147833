import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { setSideBarClose, setSideBarOpen } from "store/slices/uiSlice";
import Footer from "./Footer";
import Header from "./Header";
import SideBar from "./SideBar";

const Layout = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.UI.isSidebarOpen);
  const handleDrawerOpen = useCallback(() => {
    dispatch(setSideBarOpen());
  }, [dispatch]);

  const handleDrawerClose = useCallback(() => {
    dispatch(setSideBarClose());
  }, [dispatch]);

  const matches = useMediaQuery((theme) => theme.breakpoints.down("md"));
  useEffect(() => {
    matches && dispatch(setSideBarClose());
  }, [matches, dispatch]);

  return (
    <Box
      sx={{ display: "flex" }}
      maxWidth="1920px"
      m="auto"
      position="relative"
      backgroundColor="#edf7fc"
    >
      <Header handleDrawerOpen={handleDrawerOpen} isOpen={isOpen} />
      <SideBar
        handleDrawerClose={handleDrawerClose}
        isOpen={isOpen}
        handleDrawerOpen={handleDrawerOpen}
      />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: { xs: "62px", lg: "65px", xl: "72px" },
          px: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box py={3}>
          <Outlet />
        </Box>
        <Footer isOpen={isOpen} />
      </Box>
    </Box>
  );
};

export default Layout;
