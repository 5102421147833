import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ArrowDown from "assets/icons/ArrowDown.svg";
import ArrowUp from "assets/icons/ArrowUp.svg";
import CardTitle from "components/CardTitle";
import NumberFormatter from "components/NumberFormatter";
import PropTypes from "prop-types";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import {
  STATS_CARD_TEXT_COLOR,
  STATS_NUMBER_FORMATTER_COLOR,
  STATS_PRIMARY_RED,
} from "styles/colors";

function AnalyticCards({ data }) {
  const isOpen = useSelector((state) => state.UI.isSidebarOpen);

  const difference = useCallback((item) => {
    if (!isNaN(item?.currMonthValue) && !isNaN(item?.prevMonthValue)) {
      return Math.abs(item?.currMonthValue - item?.prevMonthValue);
    } else {
      return 0;
    }
  }, []);

  return (
    <Grid container spacing={2} sx={{ "& .MuiGrid-container": { mt: 0 } }}>
      {data.map((item, index) => (
        <Grid item xs={12} md={6} lg={4} xl={3} key={index}>
          <Card
            sx={{
              minHeight: item?.prevMonthValue
                ? { md: "200px", lg: "170px", xxl: "220px" }
                : "",
            }}
          >
            <CardContent
              sx={{
                padding: 2,
              }}
            >
              <Box display="flex" justifyContent="space-between">
                <Box>
                  <CardTitle title={item?.heading} />
                  <NumberFormatter
                    sx={{
                      fontSize: { md: "3.188rem", lg: "2rem", xxl: "3.188rem" },
                      fontWeight: "bold",
                      color: STATS_NUMBER_FORMATTER_COLOR,
                      height: { md: "55px", lg: "30px", xxl: "55px" },
                    }}
                    number={
                      Math.round((Number(item?.currMonthValue) ?? 0) * 10000) /
                      10000
                    }
                  />
                  <Typography
                    sx={{
                      fontSize: "0.875rem",
                      lineHeight: "30px",
                      color: STATS_CARD_TEXT_COLOR,
                    }}
                    display={item?.prevMonthValue ? "block" : "none"}
                  >
                    This month
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  backgroundColor="#eafafd"
                  borderRadius="50%"
                  minWidth={{
                    sm: "70px",
                    md: isOpen ? "70px" : "115px",
                    lg: "70px",
                    xxl: "100px",
                  }}
                  height="100%"
                  minHeight={{
                    sm: "70px",
                    md: isOpen ? "70px" : "100px",
                    lg: "70px",
                    xxl: "100px",
                  }}
                  width="auto"
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: {
                        xs: "2.5rem",
                        md: "3rem",
                        lg: "2rem",
                        xxl: "3.25rem",
                      },
                      color: "primary.main",
                    },
                  }}
                >
                  {item?.icon}
                </Box>
              </Box>
              {item?.prevMonthValue ? (
                <Box display={!isOpen ? "flex" : "content"} gap={0.7}>
                  <Box display="flex" gap={0.7} alignItems="center">
                    <img
                      src={
                        item?.currMonthValue > item?.prevMonthValue
                          ? ArrowUp
                          : ArrowDown
                      }
                      alt="arrow"
                    />
                    <NumberFormatter
                      number={
                        Math.round((Number(difference(item)) ?? 0) * 10000) /
                        10000
                      }
                      color={
                        item?.currMonthValue > item?.prevMonthValue
                          ? "#0ea552"
                          : STATS_PRIMARY_RED
                      }
                    />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "1.063rem",
                        color: STATS_CARD_TEXT_COLOR,
                        lineHeight: 2.06,
                      }}
                    >
                      vs last month
                    </Typography>
                  </Box>
                </Box>
              ) : null}
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export default AnalyticCards;

AnalyticCards.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

AnalyticCards.defaultProps = {
  data: [],
};
