import axios from "axios";
import interceptorConfiguration from "./interceptorConfiguration";

export const http = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const httpMain = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_MAIN,
});

export const httpPages = axios.create({
  baseURL: process.env.REACT_APP_BASE_DOMAIN + "/main/v1",
});

export const httpVideo = axios.create({
  baseURL: process.env.REACT_APP_BASE_DOMAIN + "/video/v1",
});

interceptorConfiguration(http);
interceptorConfiguration(httpMain);
interceptorConfiguration(httpPages);
interceptorConfiguration(httpVideo);

export default http;
