import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import StyledDataGrid from "components/StyledGrid";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getRedemptions,
  payInvoices,
  setSelectedRowsData,
} from "store/slices/redemptionSlice";
import statusBadges from "utils/helpers/statusBadge";
import useQueryParams from "utils/hooks/useQueryParams";

export default function DataGrid() {
  const params = useQueryParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    results: redemption,
    loading,
    totalPages,
  } = useSelector((state) => state?.redemption);
  const userRole = useSelector((state) => state.auth?.userRole);
  const selectedRowsData = useSelector(
    (state) => state?.redemption?.selectedRowsData
  );

  const fetchTable = useCallback(() => {
    dispatch(getRedemptions(params));
  }, [dispatch, params]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      fetchTable();
    }, 500);
    return () => clearTimeout(timeOut);
  }, [dispatch, fetchTable]);

  const onSelectionModelChange = useCallback(
    (ids) => {
      dispatch(setSelectedRowsData(ids));
    },
    [dispatch]
  );

  const handlePay = useCallback(() => {
    dispatch(payInvoices({ selectedRowsData, navigate }));
  }, [dispatch, navigate, selectedRowsData]);

  const columns = useMemo(
    () => [
      {
        field: "serialNumber",
        headerName: "Sr",
        minWidth: 50,
        flex: 0.2,
        filterable: false,
      },
      {
        field: "fullName",
        headerName: "Full Name",
        minWidth: 170,
        flex: 0.6,
        sortable: false,
        editable: true,
        renderCell: (params) => (
          <Typography textTransform="capitalize">
            {params?.row?.fullName}
          </Typography>
        ),
      },
      {
        field: "partnerName",
        headerName: "Partner Name",
        minWidth: 150,
        flex: 0.7,
        editable: false,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Typography textTransform="capitalize">
            {params?.row?.partnerName}
          </Typography>
        ),
      },
      {
        field: "vouchersNumber",
        headerName: "Voucher #",
        minWidth: 80,
        flex: 0.5,
        sortable: false,
        editable: true,
        renderCell: (params) => (
          <Typography>{params?.row?.vouchersNumber}</Typography>
        ),
      },
      {
        field: "type",
        headerName: "Type",
        minWidth: 50,
        flex: 0.3,
        sortable: false,
        editable: true,
        renderCell: (params) => (
          <Typography textTransform="capitalize">
            {params?.row?.type}
          </Typography>
        ),
      },
      {
        field: "phone",
        headerName: "Phone",
        minWidth: 160,
        flex: 0.7,
        sortable: false,
        editable: true,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => <Typography>{params?.row?.phone}</Typography>,
      },
      {
        field: "email",
        headerName: "Email",
        minWidth: 235,
        flex: 1,
        sortable: false,
        editable: true,
        renderCell: (params) => <Typography>{params?.row?.email}</Typography>,
      },
      {
        field: "createdAt",
        headerName: "Date & Time",
        minWidth: 235,
        headerAlign: "left",
        align: "left",
        flex: 1,
        renderCell: (params) => (
          <Typography>
            {dayjs(params?.row?.createdAt).format("DD MMM, YYYY [at] hh:mm A")}
          </Typography>
        ),
      },
      {
        field: "donationStatus",
        headerName: "Status",
        minWidth: 130,
        flex: 0.4,
        editable: true,
        sortable: false,
        filterable: false,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => (
          <Chip
            sx={{
              color: statusBadges(params?.row?.donationStatus).color,
              bgcolor: statusBadges(params?.row?.donationStatus).bgColor,
              borderRadius: "5px",
              height: "26px",
              textTransform: "capitalize",
            }}
            variant="contained"
            label={
              params?.row?.donationStatus === "pending" ? "Pending" : "Donated"
            }
          />
        ),
      },
    ],
    []
  );
  return (
    <>
      {redemption ? (
        <>
          {selectedRowsData.length > 0 &&
            (userRole === "admin" || userRole === "superAdmin") && (
              <Button
                size="large"
                variant="contained"
                py={1}
                sx={{ color: "#fff", width: "fit-content" }}
                onClick={handlePay}
              >
                Pay Now
              </Button>
            )}
          <StyledDataGrid
            rows={redemption}
            columns={columns}
            loading={loading}
            totalPages={totalPages}
            onSelectionModelChange={onSelectionModelChange}
            selectionModel={selectedRowsData}
            isRowSelectable={({ row }) =>
              row.donationStatus !== "donated" && row.type !== "replay"
            }
          />
        </>
      ) : (
        <Typography display="flex" justifyContent="center" mt={4}>
          No record found
        </Typography>
      )}
    </>
  );
}
