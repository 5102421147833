import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import queryString from "query-string";
import { memo, useCallback, useLayoutEffect, useState } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import INVOICE_FILTER from "utils/constants/invoiceFilter";

function Filters() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const query = queryString.parse(search);
  const [value, setValue] = useState("all");

  const handleChange = useCallback(
    (_, value) => {
      setValue(value);
      // Necessary for performance
      // eslint-disable-next-line no-unused-vars
      const { invoiceType, ...rest } = query;
      let params = { ...rest };
      switch (value) {
        case INVOICE_FILTER.SALE_AGENT:
          params.invoiceType = INVOICE_FILTER.SALE_AGENT;
          break;
        case INVOICE_FILTER.GOLD_PARTNER:
          params.invoiceType = INVOICE_FILTER.GOLD_PARTNER;
          break;
        case INVOICE_FILTER.CUSTOMER:
          params.invoiceType = INVOICE_FILTER.CUSTOMER;
          break;
        case INVOICE_FILTER.PLATFORM:
          params.invoiceType = INVOICE_FILTER.PLATFORM;
          break;
        default:
          break;
      }
      navigate({
        pathname: "/invoices",
        search: createSearchParams(params).toString(),
      });
    },
    [navigate, query]
  );

  useLayoutEffect(() => {
    if (query.invoiceType) {
      setValue(query.invoiceType);
    }
  }, [query.invoiceType]);

  return (
    <Box sx={containerStyles}>
      <Box
        sx={{
          backgroundColor: "white",
          marginBottom: { xs: 2, md: 0 },
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="Product status filters"
        >
          <Tab label="All Invoices" value="all" />
          <Tab label="Sale Agent" value={INVOICE_FILTER.SALE_AGENT} />
          <Tab label="Gold Partner" value={INVOICE_FILTER.GOLD_PARTNER} />
          <Tab label="Customer" value={INVOICE_FILTER.CUSTOMER} />
          <Tab label="Platform" value={INVOICE_FILTER.PLATFORM} />
        </Tabs>
      </Box>
    </Box>
  );
}

export default memo(Filters);

const containerStyles = {
  display: { xs: "grid", md: "flex" },
  justifyContent: "space-between",
  alignItems: "baseline",
  "& .styled-button": {
    borderRadius: "1px",
    padding: "10px",
  },
  "& .MuiOutlinedInput-root": { borderRadius: "20px" },
  "& .MuiTab-root": { textTransform: "capitalize" },
};
