import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import USER_ROLE from "utils/constants/userRole";
import { getErrorMessage, getResponseData } from "utils/helpers/apiDataHelpers";
import { toast } from "utils/hooks/useToast";
import http from "utils/httpRequests/http";

export const getAnalytics = createAsyncThunk(
  "dashboard/getAnalytics",
  async (date, { getState, rejectWithValue }) => {
    const userRole = getState().auth?.userRole;
    const URL =
      userRole === USER_ROLE.SUPER_ADMIN || userRole === USER_ROLE.ADMIN
        ? `/analytics/admin`
        : `/analytics`;
    try {
      const response = await http.get(URL, {
        params: {
          date: date || new Date().toISOString(),
        },
      });
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

const initialState = {
  loading: {
    analytics: true,
  },
  analytics: {},
  error: {
    analytics: "",
  },
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    // Actions
    resetState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAnalytics.pending, (state) => {
        state.loading.analytics = true;
        state.error.analytics = "";
      })
      .addCase(getAnalytics.fulfilled, (state, action) => {
        state.loading.analytics = false;
        state.error.analytics = "";
        state.analytics = action.payload;
      })
      .addCase(getAnalytics.rejected, (state, action) => {
        state.loading.analytics = false;
        state.error.analytics = action?.payload;
      });
  },
});

// Action creators generated for each case reducer function
export const { resetState } = dashboardSlice.actions;

// Exporting default reducer
export default dashboardSlice.reducer;
