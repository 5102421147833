import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { RecaptchaVerifier, getAuth } from "firebase/auth";
import { Form, Formik } from "formik";
import parsePhoneNumber from "libphonenumber-js";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendPhoneOTP } from "services/authServices";
import { resetRedemptionState } from "store/slices/redemptionSlice";
import StyledMuiCard from "utils/helpers/StyledMuiCard";
import { getErrorMessage } from "utils/helpers/apiDataHelpers";
import StyledTextField from "utils/helpers/styledTextField";
import { toast } from "utils/hooks/useToast";
import http from "utils/httpRequests/http";
import { firebaseApp } from "utils/lib/FirebaseAppSettings";
import * as Yup from "yup";
import PhoneVerification from "./PhoneVerification";

const auth = getAuth(firebaseApp);

function RedemptionForm() {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { loading } = useSelector((state) => state?.redemption);

  const initializeRecaptchaVerifier = useCallback(() => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
        },
        auth
      );
    }
  }, []);

  const initialValues = useMemo(
    () => ({
      phone: "",
      vouchersNumber: "",
    }),
    []
  );

  const handleSubmit = async (values) => {
    try {
      const data = {
        phone: values.phone,
        vouchersNumber: values.vouchersNumber,
      };
      const response = await http.post(`/invoices/valid`, data);

      if (response?.data?.data) {
        toast.success("Vouchers Number are successfully validated");
        setIsLoading(true);
        const phoneNumber = values?.phone;
        await sendPhoneOTP(auth, phoneNumber, setError, setIsSubmitting);
      }
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
    }
  };

  useEffect(() => {
    return () => dispatch(resetRedemptionState());
  }, [dispatch]);

  useEffect(() => {
    initializeRecaptchaVerifier();
    return () => {
      if (window?.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
        window.recaptchaVerifier = null;
      }
    };
  }, [initializeRecaptchaVerifier]);

  const vouchersNumber = [];

  return (
    <Box px={1}>
      <Box id="recaptcha-container" sx={{ all: "unset !important" }} />
      <Typography
        variant="h1"
        fontWeight="600"
        color="primary.main"
        fontSize={{ lg: "2.6rem", sm: "2rem" }}
        my={2}
      >
        Redeem
      </Typography>

      <Formik
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <>
            {!isLoading && (
              <Form>
                <StyledMuiCard
                  title="Reedem Information"
                  subTitle="Please enter the basic information of redeem such as invoice numbers and phone."
                >
                  <CardContent>
                    <Grid container spacing={2} p={{ xs: 1, md: 1.5 }}>
                      <Grid item xs={4} lg={4}>
                        <Box display="flex" flexDirection="column" gap={2}>
                          <Typography
                            variant="h3"
                            fontWeight="bold"
                            fontSize="1.25rem"
                            color="text.secondary"
                          >
                            Phone
                          </Typography>
                          <StyledTextField
                            fullWidth
                            type="tel"
                            size={isSmall ? "small" : "large"}
                            {...formik.getFieldProps("phone")}
                            placeholder="+971 XXXX X XXXXXXX"
                            error={
                              !!formik.touched.phone && !!formik.errors.phone
                            }
                            helperText={
                              formik.touched.phone && formik.errors.phone
                                ? formik.errors.phone
                                : "Enter the valid phone number including country code."
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={8} lg={8}>
                        <Box display="flex" flexDirection="column" gap={2}>
                          <Typography
                            variant="h3"
                            fontWeight="bold"
                            fontSize="1.25rem"
                            color="text.secondary"
                          >
                            Voucher Numbers
                          </Typography>
                          <Autocomplete
                            multiple
                            id="tags-filled"
                            options={vouchersNumber.map(
                              (option) => option?.title
                            )}
                            value={formik.values.vouchersNumber}
                            onChange={(event, value) => {
                              formik.setFieldValue("vouchersNumber", value);
                            }}
                            freeSolo
                            renderTags={(value, getTagProps) =>
                              value &&
                              value.map((option, index) => (
                                <Chip
                                  key={index}
                                  variant="outlined"
                                  label={option}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) => (
                              <StyledTextField
                                fullWidth
                                {...params}
                                type="text"
                                size="large"
                                placeholder="Enter voucher numbers"
                                error={
                                  !!formik.touched.vouchersNumber &&
                                  !!formik.errors.vouchersNumber
                                }
                                helperText={
                                  formik.touched.vouchersNumber &&
                                  formik.errors.vouchersNumber
                                    ? formik.errors.vouchersNumber
                                    : "Enter the valid voucher numbers."
                                }
                              />
                            )}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                    <Box textAlign="right" mt={2} mr={1}>
                      <Button
                        disableElevation
                        type="submit"
                        color="primary"
                        variant="contained"
                        disabled={loading}
                        sx={{
                          fontWeight: "bold",
                          minWidth: { md: 100, xl: 250 },
                          height: { xs: 50, xl: 55 },
                        }}
                      >
                        Continue
                      </Button>
                    </Box>
                  </CardContent>
                </StyledMuiCard>
              </Form>
            )}
            <PhoneVerification
              values={formik?.values}
              isSubmitting={isSubmitting}
              isLoading={isLoading}
              setIsSubmitting={setIsSubmitting}
              error={error}
              setError={setError}
            />
          </>
        )}
      </Formik>
    </Box>
  );
}

export default memo(RedemptionForm);

const phoneValidation = (value) => {
  try {
    const phoneNumber = parsePhoneNumber(value);

    if (phoneNumber && phoneNumber.isValid()) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

const validationSchema = Yup.object().shape({
  vouchersNumber: Yup.array()
    .of(Yup.string().required("Invoice ID is required"))
    .min(1, "At least one invoice ID is required"),
  phone: Yup.string()
    .test("phone", "Invalid phone number", phoneValidation)
    .required("Required*"),
});
