import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import PageHeader from "components/PageHeader";
import { Form, Formik } from "formik";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { resetState } from "store/slices/filtersSlice";
import {
  createPreference,
  getPreference,
  resetPreferenceState,
  updatePreference,
} from "store/slices/preferencesSlice";
import { CURRENCY } from "utils/constants/constants";
import reduceObject from "utils/helpers/reduceObject";
import { toast } from "utils/hooks/useToast";
import * as Yup from "yup";
import BasicInformation from "./BasicInformation";
import UserInformation from "./UserInformation";

function PreferencesForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = useParams()?.id ?? "";
  const loading = useSelector(
    (state) => state?.preference?.loading?.preference
  );

  const preference = useSelector((state) => state?.preferences?.preference);

  const initialValues = useMemo(
    () => ({
      id: preference?.id ?? "",
      user: preference?.user ?? "",
      userName: preference?.userName ?? "",
      type: preference?.type ?? "amount",
      currency: preference?.currency ?? String(CURRENCY).toLowerCase(),
      amount: preference?.amount ?? "",
      chargesIncluded: preference?.chargesIncluded ?? false,
      goldPartner: preference?.goldPartner ?? false,
    }),
    [preference]
  );

  const handleSubmit = useCallback(
    (values) => {
      let data = reduceObject(values, initialValues);

      if (Object.keys(data).length === 0) {
        toast.info("No changes detected");
        return navigate("/users/preferences");
      } else {
        if (id)
          dispatch(updatePreference({ id, data }))
            .unwrap()
            .then(() => {
              navigate("/users/preferences");
            });
        else {
          dispatch(createPreference(data))
            .unwrap()
            .then(() => {
              navigate("/users/preferences");
            });
        }
      }
    },
    [dispatch, id, initialValues, navigate]
  );

  useEffect(() => {
    if (id) {
      dispatch(getPreference(id));
      return () => {
        dispatch(resetState());
        dispatch(resetPreferenceState());
      };
    }
  }, [dispatch, id]);

  return (
    <Stack direction="column" spacing={3}>
      <PageHeader title={`${id ? "Update" : "Create"} Preference`} />
      <Formik
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={6} lg={12}>
                <UserInformation formik={formik} />
              </Grid>
              <Grid item xs={6} lg={12}>
                <BasicInformation formik={formik} />
              </Grid>
              {/* Action Buttons */}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={formik.handleSubmit}
                  disabled={loading}
                  sx={{
                    fontWeight: "bold",
                    minWidth: { md: 100, lg: 250 },
                    height: { xs: 50, lg: 55 },
                  }}
                >
                  {id ? "Update" : "Create"}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Stack>
  );
}

const validationSchema = Yup.object().shape({
  id: Yup.string(),
  user: Yup.string().required("User is required"),
  userName: Yup.string(),
  type: Yup.string().required("Type is required"),
  currency: Yup.string().required("Currency is required"),
  amount: Yup.number()
    .required("Amount is required")
    .min(1, "Min amount should be 1")
    .max(1000000, "Max amount should be 1000000"),
  chargesIncluded: Yup.boolean(),
  goldPartner: Yup.boolean(),
});

export default memo(PreferencesForm);
