import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import PageHeader from "components/PageHeader";
import { Form, Formik } from "formik";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { store } from "store/configureStore";
import {
  createMasterTable,
  getTableById,
  resetMasterTableState,
  updateMasterTable,
} from "store/slices/masterTableSlice";
import StyledMuiCard from "utils/helpers/StyledMuiCard";
import parseEditorValue from "utils/helpers/parseEditorValue";
import reduceObject from "utils/helpers/reduceObject";
import StyledTextField from "utils/helpers/styledTextField";
import * as Yup from "yup";
import TableDescriptionSection from "./TableDescriptionSection";

function MasterTableForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = useParams().id;
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const { table, loading } = useSelector((state) => state?.table);

  const initialValues = useMemo(
    () => ({
      key: table?.key ?? "",
      keyValue: table?.keyValue ?? "",
      label: table?.label ?? "",
      active: table?.active ?? "",
      unit: table?.unit ?? "",
      dataType: table?.dataType ?? "",
      description: table?.description
        ? parseEditorValue(table?.description)
        : "",
    }),
    [table]
  );

  const handleSubmit = useCallback(
    (values) => {
      if (id) {
        const data = {
          key: values.key,
          keyValue: values.keyValue,
          label: values.label,
          active: values.active,
          unit: values.unit,
          dataType: values.dataType,
          description: values.description,
        };

        let formValues = { ...data };
        let tableValues = { ...table };
        let updatedValues = reduceObject(formValues, tableValues);

        if (Object.keys(updatedValues).length === 0) {
          return navigate("/master-table");
        } else {
          dispatch(updateMasterTable({ id, updatedValues }))
            .unwrap()
            .then(() => {
              navigate("/master-table");
            });
        }
      } else {
        dispatch(
          createMasterTable({
            key: values.key,
            keyValue: values.keyValue,
            label: values.label,
            active: values.active,
            unit: values.unit,
            dataType: values.dataType,
            description: values.description,
          })
        )
          .unwrap()
          .then(() => {
            navigate("/master-table");
          });
      }
    },
    [dispatch, id, navigate, table]
  );

  useEffect(() => {
    return () => dispatch(resetMasterTableState());
  }, [dispatch]);

  return (
    <Stack direction="column" spacing={3}>
      <PageHeader title={`${id ? "Update" : "Create"} Master Table`} />
      <Formik
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <StyledMuiCard
              title="Table Information"
              subTitle="Please enter the basic information of master table such as label , name , value , status , unit and description."
            >
              <CardContent>
                <Grid container spacing={2} p={{ xs: 1, md: 1.5 }}>
                  <Grid item xs={6} lg={6}>
                    <Box display="flex" flexDirection="column" gap={2}>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontSize="1.25rem"
                        color="text.secondary"
                      >
                        Label
                      </Typography>
                      <StyledTextField
                        fullWidth
                        type="tel"
                        size={isSmall ? "small" : "large"}
                        {...formik.getFieldProps("label")}
                        error={!!formik.touched.label && !!formik.errors.label}
                        helperText={
                          formik.touched.label && formik.errors.label
                            ? formik.errors.label
                            : "Enter the valid label against name."
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={3} lg={3}>
                    <Box display="flex" flexDirection="column" gap={2}>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontSize="1.25rem"
                        color="text.secondary"
                      >
                        Name
                      </Typography>
                      <StyledTextField
                        fullWidth
                        type="text"
                        size={isSmall ? "small" : "large"}
                        {...formik.getFieldProps("key")}
                        error={Boolean(formik.touched.key && formik.errors.key)}
                        helperText={
                          formik.touched.key && !!formik.errors.key
                            ? formik.errors.key
                            : "Enter a key name of the table here"
                        }
                      />
                    </Box>
                  </Grid>
                  <Grid item xs={3} lg={3}>
                    <Box display="flex" flexDirection="column" gap={2}>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontSize="1.25rem"
                        color="text.secondary"
                      >
                        Value
                      </Typography>
                      <StyledTextField
                        fullWidth
                        size={isSmall ? "small" : "large"}
                        type="text"
                        {...formik.getFieldProps("keyValue")}
                        error={
                          !!formik.touched.keyValue && !!formik.errors.keyValue
                        }
                        helperText={
                          formik.touched.keyValue && formik.errors.keyValue
                            ? formik.errors.keyValue
                            : "Enter the valid value of the name here."
                        }
                      />
                    </Box>
                  </Grid>

                  <Grid item xs={3} lg={3}>
                    <Box display="flex" flexDirection="column" gap={2}>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontSize="1.25rem"
                        color="text.secondary"
                      >
                        Status
                      </Typography>
                      <StyledTextField
                        fullWidth
                        select
                        type="text"
                        {...formik.getFieldProps("active")}
                        error={Boolean(
                          formik.touched.active && formik.errors.active
                        )}
                        helperText={
                          formik.touched.active && !!formik.errors.active
                            ? formik.errors.active
                            : "Select the status of the voucher"
                        }
                      >
                        <MenuItem value="true">Active</MenuItem>
                        <MenuItem value="false">Inactive</MenuItem>
                      </StyledTextField>
                    </Box>
                  </Grid>
                  <Grid item xs={3} lg={3}>
                    <Box display="flex" flexDirection="column" gap={2}>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontSize="1.25rem"
                        color="text.secondary"
                      >
                        Units
                      </Typography>
                      <StyledTextField
                        fullWidth
                        select
                        type="text"
                        {...formik.getFieldProps("unit")}
                        error={Boolean(
                          formik.touched.unit && formik.errors.unit
                        )}
                        helperText={
                          formik.touched.unit && !!formik.errors.unit
                            ? formik.errors.unit
                            : "Select the unit of the table"
                        }
                      >
                        <MenuItem value="gm">Gm</MenuItem>
                        <MenuItem value="kg">Kg</MenuItem>
                        <MenuItem value="amount">Amount</MenuItem>
                        <MenuItem value="percentage">Percentage</MenuItem>
                      </StyledTextField>
                    </Box>
                  </Grid>
                  <Grid item xs={3} lg={3}>
                    <Box display="flex" flexDirection="column" gap={2}>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontSize="1.25rem"
                        color="text.secondary"
                      >
                        Data Type
                      </Typography>
                      <StyledTextField
                        fullWidth
                        select
                        type="text"
                        {...formik.getFieldProps("dataType")}
                        error={Boolean(
                          formik.touched.dataType && formik.errors.dataType
                        )}
                        helperText={
                          formik.touched.dataType && !!formik.errors.dataType
                            ? formik.errors.dataType
                            : "Select the dataType of the table"
                        }
                      >
                        <MenuItem value="string">String</MenuItem>
                        <MenuItem value="number">Number</MenuItem>
                      </StyledTextField>
                    </Box>
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <TableDescriptionSection formik={formik} />
                  </Grid>
                </Grid>
                <Box textAlign="right" mt={2} mr={1}>
                  <Button
                    disableElevation
                    type="submit"
                    color="primary"
                    variant="contained"
                    disabled={loading}
                    sx={{
                      fontWeight: "bold",
                      minWidth: { md: 100, xl: 250 },
                      height: { xs: 50, xl: 55 },
                    }}
                  >
                    {id ? "Update" : "Create"}
                  </Button>
                </Box>
              </CardContent>
            </StyledMuiCard>
          </Form>
        )}
      </Formik>
    </Stack>
  );
}

export default memo(MasterTableForm);

export function loadMasterTable({ params }) {
  const tableId = params?.id;
  store.dispatch(getTableById(tableId ?? ""));
  return null;
}

const validationSchema = Yup.object().shape({
  key: Yup.string()
    .trim()
    .required("Required*")
    .min(3, "Name is too short")
    .max(50, "Name is too long"),
  keyValue: Yup.string()
    .trim()
    .required("Required*")
    .min(1, "Name is too short")
    .max(50, "Name is too long"),
  label: Yup.string()
    .trim()
    .required("Required*")
    .min(3, "Name is too short")
    .max(50, "Name is too long"),
  active: Yup.string().required("Required*"),
  unit: Yup.string().required("Required*"),
  description: Yup.string()
    .trim()
    .required("Required*")
    .min(20, "Description must be at least 80 characters long"),
});
