import { CURRENCY } from "utils/constants/constants";

const formatCurrency = (number = 0, locale = "en-US") =>
  new Intl.NumberFormat(locale, {
    style: "currency",
    currency: CURRENCY,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number);

export default formatCurrency;

export const formatCurrencyNumber = (number = 0) => {
  // Add thousands separator
  const formattedNumber = number
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Format the number as a currency string
  return `${formattedNumber}`;
};
