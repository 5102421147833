import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from "prop-types";
import { memo } from "react";
import { useSelector } from "react-redux";
import { CURRENCY } from "utils/constants/constants";
import StyledMuiCard from "utils/helpers/StyledMuiCard";
import StyledTextField from "utils/helpers/styledTextField";

function BasicInformation({ formik }) {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const userRole = useSelector((state) => state.auth?.userRole);

  return (
    <StyledMuiCard
      title="Basic Information"
      subTitle="Please enter the information of preference"
    >
      <CardContent>
        <Grid container spacing={2} p={{ xs: 1, md: 1.5 }}>
          {userRole === "saleAgent" ||
          userRole === "admin" ||
          userRole === "superAdmin" ? (
            <Grid item xs={6} lg={6}>
              <Box display="flex" flexDirection="column" gap={2}>
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  fontSize="1.25rem"
                  color="text.secondary"
                >
                  Include Charges
                </Typography>
                <StyledTextField
                  select
                  fullWidth
                  type="text"
                  size={isSmall ? "small" : "large"}
                  {...formik.getFieldProps("chargesIncluded")}
                  error={Boolean(
                    formik.touched.chargesIncluded &&
                      formik.errors.chargesIncluded
                  )}
                  helperText={
                    formik.touched.chargesIncluded &&
                    !!formik.errors.chargesIncluded
                      ? formik.errors.chargesIncluded
                      : "Please select an option"
                  }
                >
                  <MenuItem value={false}>No</MenuItem>
                  <MenuItem value={true}>Yes</MenuItem>
                </StyledTextField>
              </Box>
            </Grid>
          ) : null}
          {userRole === "goldPartner" ||
          userRole === "admin" ||
          userRole === "superAdmin" ? (
            <>
              <Grid item xs={6} lg={6}>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Typography
                    variant="h3"
                    fontWeight="bold"
                    fontSize="1.25rem"
                    color="text.secondary"
                  >
                    Currency
                  </Typography>
                  <StyledTextField
                    select
                    fullWidth
                    type="text"
                    disabled={true}
                    size={isSmall ? "small" : "large"}
                    {...formik.getFieldProps("currency")}
                    error={Boolean(
                      formik.touched.currency && formik.errors.currency
                    )}
                    helperText={
                      formik.touched.currency && !!formik.errors.currency
                        ? formik.errors.currency
                        : "Please select the Currency type"
                    }
                  >
                    <MenuItem value={String(CURRENCY).toLowerCase()}>
                      {String(CURRENCY).toUpperCase()}
                    </MenuItem>
                  </StyledTextField>
                </Box>
              </Grid>

              <Grid item xs={6} lg={6}>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Typography
                    variant="h3"
                    fontWeight="bold"
                    fontSize="1.25rem"
                    color="text.secondary"
                  >
                    Type
                  </Typography>
                  <StyledTextField
                    select
                    fullWidth
                    type="text"
                    size={isSmall ? "small" : "large"}
                    {...formik.getFieldProps("type")}
                    error={Boolean(formik.touched.type && formik.errors.type)}
                    helperText={
                      formik.touched.type && !!formik.errors.type
                        ? formik.errors.type
                        : "Please select the amount type of preference"
                    }
                  >
                    <MenuItem value="amount">Amount</MenuItem>
                    <MenuItem value="percentage">Percentage</MenuItem>
                  </StyledTextField>
                </Box>
              </Grid>

              <Grid item xs={6} lg={6}>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Typography
                    variant="h3"
                    fontWeight="bold"
                    fontSize="1.25rem"
                    color="text.secondary"
                  >
                    Amount
                  </Typography>
                  <StyledTextField
                    fullWidth
                    type="number"
                    disabled={true}
                    size={isSmall ? "small" : "large"}
                    {...formik.getFieldProps("amount")}
                    error={!!formik.touched.amount && !!formik.errors.amount}
                    helperText={
                      formik.touched.amount && formik.errors.amount
                        ? formik.errors.amount
                        : "Enter the valid amount here"
                    }
                    sx={{
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                        {
                          display: "none",
                        },
                      "& input[type=number]": {
                        MozAppearance: "textfield",
                      },
                      "& .MuiFormHelperText-root": {
                        ml: 0,
                      },
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={6} lg={6}>
                <Box display="flex" flexDirection="column" gap={2}>
                  <Typography
                    variant="h3"
                    fontWeight="bold"
                    fontSize="1.25rem"
                    color="text.secondary"
                  >
                    Gold Partner
                  </Typography>
                  <StyledTextField
                    select
                    fullWidth
                    type="text"
                    size={isSmall ? "small" : "large"}
                    {...formik.getFieldProps("goldPartner")}
                    error={Boolean(
                      formik.touched.goldPartner && formik.errors.goldPartner
                    )}
                    helperText={
                      formik.touched.goldPartner && !!formik.errors.goldPartner
                        ? formik.errors.goldPartner
                        : "Please select an option"
                    }
                  >
                    <MenuItem value={false}>No</MenuItem>
                    <MenuItem value={true}>Yes</MenuItem>
                  </StyledTextField>
                </Box>
              </Grid>
            </>
          ) : null}
        </Grid>
      </CardContent>
    </StyledMuiCard>
  );
}

BasicInformation.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(BasicInformation);
