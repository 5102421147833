import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Logo from "assets/icons/GoldRushLogo.svg";
import Settings from "assets/icons/Settings.svg";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import SideBarListItems from "utils/constants/SideBarListItems";
import RoleTitle from "utils/helpers/RoleTitle";

const openedMixin = (theme) => ({
  [theme.breakpoints.up("xl")]: {
    width: 370,
  },
  [theme.breakpoints.down("xl")]: {
    width: 270,
  },
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  paddingBottom: "10px",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  [theme.breakpoints.up("xl")]: {
    width: 370,
  },
  [theme.breakpoints.down("xl")]: {
    maxWidth: 270,
  },
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SideBar({
  handleDrawerClose,
  isOpen,
  handleDrawerOpen,
}) {
  const userRole = useSelector((state) => state.auth?.userRole);
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isMedium = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const isLarge = useMediaQuery((theme) => theme.breakpoints.down("xl"));

  return (
    <Box display="flex" height="100vh">
      <Drawer
        variant="permanent"
        open={isOpen}
        PaperProps={{
          sx: {
            backgroundColor: "primary.main",
            color: "white",
            position: "fixed",
            left: "auto",
          },
        }}
      >
        <Box height="100vh" display="flex" flexDirection="column">
          <Box>
            <DrawerHeader height="6.5rem">
              {isOpen ? (
                <Box padding="10px 20px 0 20px">
                  <img
                    src={Logo}
                    alt="logo"
                    height="auto"
                    width={
                      isSmall
                        ? "80px"
                        : isMedium
                        ? "100px"
                        : isLarge
                        ? "125px"
                        : "176px"
                    }
                  />
                </Box>
              ) : null}

              {isOpen ? (
                <IconButton onClick={handleDrawerClose}>
                  <ChevronLeftIcon
                    sx={{
                      color: "white",
                    }}
                  />
                </IconButton>
              ) : (
                <IconButton onClick={handleDrawerOpen}>
                  <ChevronRightIcon
                    sx={{
                      color: "white",
                      ml: "5px",
                    }}
                  />
                </IconButton>
              )}
            </DrawerHeader>
            {isOpen ? (
              <Box
                textAlign="end"
                variant="h6"
                fontWeight="bold"
                lineHeight="10px"
                fontSize="1.525rem"
                paddingRight="22px"
              >
                <RoleTitle role={userRole} />
              </Box>
            ) : null}
          </Box>
          <Box
            sx={{
              flexGrow: "1",
              overflowY: "auto",
              overflowX: "hidden",
              "&::-webkit-scrollbar": {
                width: 5,
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "none",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "white",
                borderRadius: 2,
              },
            }}
          >
            <List
              sx={{
                "& .Mui-disabled": {
                  opacity: "1 !important",
                },
                pt: "22px",
              }}
            >
              {SideBarListItems.map(
                (route) =>
                  route?.roles?.includes(userRole) && (
                    <ListItem
                      key={route?.path}
                      disablePadding
                      sx={{
                        display: "block",
                      }}
                    >
                      <ListItemButton
                        dense
                        disabled={route?.disabled}
                        to={route?.path}
                        component={NavLink}
                        sx={{
                          minHeight: 48,
                          justifyContent: isOpen ? "initial" : "center",
                          px: 3,
                        }}
                        // end
                        style={({ isActive }) =>
                          isActive ? { backgroundColor: "#8e6000" } : undefined
                        }
                      >
                        {route.icon && (
                          <Tooltip
                            disableHoverListener={isOpen}
                            title={route.title}
                            placement="right-end"
                          >
                            <ListItemIcon
                              sx={{
                                minWidth: 0,
                                mr: isOpen ? 1 : "auto",
                                justifyContent: "center",
                                color: "white",
                              }}
                            >
                              {typeof route.icon === "string" ? (
                                <img
                                  src={route.icon}
                                  alt="icon"
                                  width="20px"
                                  height="20px"
                                />
                              ) : (
                                <>{route.icon}</>
                              )}
                            </ListItemIcon>
                          </Tooltip>
                        )}

                        <ListItemText
                          primary={
                            <Typography
                              variant="h6"
                              fontWeight="bold"
                              lineHeight="10px"
                              fontSize="1.525rem"
                              paddingLeft="5px"
                            >
                              {route.title}
                            </Typography>
                          }
                          sx={{ opacity: isOpen ? 1 : 0 }}
                        />
                      </ListItemButton>
                      <Collapse in={isOpen} unmountOnExit timeout="auto">
                        {route.children && (
                          <List id="children" sx={{ py: 0 }}>
                            {route.children?.map(
                              (_route, _index) =>
                                _route?.roles?.includes(userRole) && (
                                  <ListItem
                                    key={_index}
                                    disablePadding
                                    sx={{ display: "block" }}
                                  >
                                    <ListItemButton
                                      component={NavLink}
                                      to={`${route.path + _route.path}`}
                                      disabled={_route?.disabled}
                                      sx={{
                                        justifyContent: isOpen
                                          ? "initial"
                                          : "center",
                                        py: 0,
                                      }}
                                      style={({ isActive }) =>
                                        isActive
                                          ? { backgroundColor: "#8e6000" }
                                          : undefined
                                      }
                                    >
                                      <ListItemText
                                        inset
                                        primary={_route.title}
                                        sx={{
                                          opacity: isOpen ? 1 : 0,
                                          justifyContent: "center",
                                          paddingLeft: "50px",
                                          "& .MuiTypography-root": {
                                            fontSize: "1.212rem",
                                          },
                                        }}
                                      />
                                    </ListItemButton>
                                  </ListItem>
                                )
                            )}
                          </List>
                        )}
                      </Collapse>
                    </ListItem>
                  )
              )}
            </List>
            <Divider />
          </Box>
          <Box>
            <List>
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  dense
                  to="/settings"
                  component={NavLink}
                  sx={{
                    minHeight: 48,
                    justifyContent: isOpen ? "initial" : "center",
                    px: 3,
                  }}
                  style={({ isActive }) =>
                    isActive ? { backgroundColor: "#8e6000" } : undefined
                  }
                >
                  <Tooltip
                    disableHoverListener={isOpen}
                    title="Settings"
                    placement="right-end"
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: isOpen ? 1 : "auto",
                        justifyContent: "center",
                        color: "white",
                      }}
                    >
                      <img
                        src={Settings}
                        alt="icon"
                        width="20px"
                        height="20px"
                      />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText
                    primary={
                      <Typography
                        variant="h6"
                        fontWeight="bold"
                        lineHeight="10px"
                        fontSize="1.525rem"
                        paddingLeft="5px"
                      >
                        Settings
                      </Typography>
                    }
                    sx={{ opacity: isOpen ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
SideBar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDrawerClose: PropTypes.func,
  handleDrawerOpen: PropTypes.func,
};

SideBar.defaultProps = {
  isOpen: true,
};
