import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker as MUIDatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import { memo } from "react";
import useQueryParams from "utils/hooks/useQueryParams";

function DatePicker(props) {
  const date = useQueryParams()?.date;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MUIDatePicker
        label="Select Date"
        views={["year", "month", "day"]}
        value={date ? dayjs(date) : dayjs()}
        onAccept={props.onChange}
        onChange={() => true}
        slotProps={{
          textField: {
            ...props,
            sx: {
              "& .MuiFormHelperText-root": {
                fontSize: "0.9rem",
                marginLeft: 0,
              },
            },
          },
        }}
      />
    </LocalizationProvider>
  );
}

DatePicker.propTypes = {
  props: PropTypes.object,
  onChange: PropTypes.func,
};
export default memo(DatePicker);
