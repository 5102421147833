import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getErrorMessage, getResponseData } from "utils/helpers/apiDataHelpers";
import { toast } from "utils/hooks/useToast";
import { http } from "utils/httpRequests/http";

export const getInvoices = createAsyncThunk(
  "table/getInvoices",
  async (data, { getState, rejectWithValue }) => {
    const { page, limit } = getState().invoices;
    const { userRole } = getState().auth;
    const url =
      userRole === "admin" || userRole === "superAdmin"
        ? "/invoices/admin"
        : "/invoices";
    try {
      const response = await http.get(url, {
        params: {
          page: data.page ?? page,
          limit: data.limit ?? limit,
          ...data,
        },
      });
      const responseData = getResponseData(response);
      responseData &&
        responseData?.results &&
        responseData?.results.forEach((item, index) => {
          const pageStart = (responseData?.page - 1) * responseData?.limit;
          item.serialNumber = pageStart + index + 1;
        });
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const updateInvoicesStatus = createAsyncThunk(
  "invoices/updateInvoicesStatus",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.patch(`/invoices/status`, {
        invoicesId: data?.selectedRowsData,
      });
      toast.success("Invoices are updated successfully");
      data?.navigate(`/invoices?page=1&limit=20`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

const initialState = {
  loading: false,
  results: [],
  user: null,
  page: 1,
  limit: 20,
  totalPages: 0,
  totalResults: 0,
  selectedRowsData: [],
  error: "",
};

const invoicesSlice = createSlice({
  name: "invoices",
  initialState,
  reducers: {
    resetState: () => initialState,
    resetMasterTableState: (state) => {
      state.table = null;
    },
    setSelectedRowsData: (state, action) => {
      state.selectedRowsData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInvoices.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getInvoices.fulfilled, (state, action) => {
        state.loading = false;
        state.error = "";
        state.results = action.payload?.results;
        state.page = action.payload?.page;
        state.limit = action.payload?.limit;
        state.totalResults = action.payload?.totalResults;
        state.totalPages = action.payload?.totalPages;
      })
      .addCase(getInvoices.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      })
      .addCase(updateInvoicesStatus.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(updateInvoicesStatus.fulfilled, (state) => {
        state.loading = false;
        state.error = "";
        state.selectedRowsData = [];
      })
      .addCase(updateInvoicesStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.payload;
      });
  },
});

// Action creators generated for each case reducer function
export const { resetState, resetMasterTableState, setSelectedRowsData } =
  invoicesSlice.actions;

// Exporting default reducer
export default invoicesSlice.reducer;
