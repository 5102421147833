import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DateFilter from "components/Pickers/DateRange";
import { useCallback, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { FILTER_TEXT_COLOR } from "styles/colors";
import useQueryParams from "utils/hooks/useQueryParams";

export default function DateFilters() {
  const navigate = useNavigate();
  const params = useQueryParams();
  const [open, setOpen] = useState(false);

  const toggle = () => {
    setOpen(!open);
    if (open === true) {
      navigate(`/redemptions`);
    }
  };

  const handleChange = useCallback(
    (range) => {
      let _params = params;
      _params.from = range.startDate.toDateString();
      _params.to = range.endDate.toDateString();
      navigate({
        search: createSearchParams(_params).toString(),
      });
    },
    [navigate, params]
  );

  return (
    <Box sx={containerStyles}>
      <Box display="flex" gap={2}>
        {!open && (
          <Button
            variant="text"
            size="small"
            onClick={toggle}
            className="Filter-toggle-btn"
            sx={{
              borderBottom: "2px solid",
              borderColor: "primary.main",
              borderRadius: 0,
              textTransform: "none",
            }}
          >
            Filter
          </Button>
        )}
        {open && (
          <>
            <DateFilter onChange={handleChange} />
          </>
        )}
        {open && (
          <Button
            onClick={toggle}
            variant="text"
            className="Filter-toggle-btn"
            sx={{
              borderBottom: "2px solid #707070",
              borderRadius: 0,
              color: FILTER_TEXT_COLOR,
              textTransform: "none",
            }}
          >
            Clear All
          </Button>
        )}
      </Box>
    </Box>
  );
}

const containerStyles = {
  display: "flex",
  flexDirection: { xs: "column", xl: "row" },
  justifyContent: { xl: "space-between" },
  alignItems: { xl: "center" },
  gap: { xs: 2, xl: 0 },
  "& .MuiFormControl-root": {
    borderColor: "#fff",
  },
  "& .MuiOutlinedInput-root": {
    padding: "0.5rem",
    height: "42px",
    width: "150px",
    "& fieldset": {
      borderColor: "primary.main",
    },
    "&:hover": {
      backgroundColor: "#edf8fd",
      "& fieldset": {
        borderColor: "#02b4fffc",
      },
    },
  },
  "& .MuiSelect-select": { height: "29px !important" },
  "& .Filter-toggle-btn": {
    height: 42,
    textTransform: "none",
    fontSize: "1rem",
  },
};
