import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import WritingIcon from "assets/icons/WritingIcon.svg";
import deleteIcon from "assets/icons/deleteIcon.svg";
import ConfirmDelete from "components/Dialog/ConfirmDelete";
import StyledDataGrid from "components/StyledGrid";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setConfirmDeletionDialogOpen,
  setDeleteId,
} from "store/slices/uiSlice";
import { deleteUser, getUsers } from "store/slices/usersSlice";
import USER_ROLE from "utils/constants/userRole";
import useQueryParams from "utils/hooks/useQueryParams";

export default function DataGrid() {
  const params = useQueryParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    results: users,
    loading,
    totalPages,
  } = useSelector((state) => state.users);
  const loggedUserId = useSelector((state) => state.auth?.id ?? "");
  const loggedUserRole = useSelector((state) => state.auth?.userRole ?? "");

  const handleClickOpen = useCallback(
    (userId) => {
      dispatch(setConfirmDeletionDialogOpen());
      dispatch(setDeleteId(userId));
    },
    [dispatch]
  );

  const fetchUsers = useCallback(() => {
    dispatch(getUsers(params));
  }, [dispatch, params]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      fetchUsers();
    }, 500);
    return () => clearTimeout(timeOut);
  }, [dispatch, fetchUsers]);

  const columns = useMemo(
    () => [
      {
        field: "serialNumber",
        headerName: "Sr",
        minWidth: 50,
        flex: 0.1,
        filterable: false,
      },
      {
        field: "userName",
        headerName: "Name",
        minWidth: 250,
        flex: 1,
        editable: true,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Typography textTransform="capitalize">
            {params?.row?.fullname}
          </Typography>
        ),
      },
      {
        field: "role",
        headerName: "Role",
        minWidth: 125,
        flex: 0.5,
        editable: false,
        sortable: false,
        filterable: false,
        renderCell: (params) => (
          <Typography textTransform="capitalize">
            {params?.row?.role === USER_ROLE.SUPER_ADMIN
              ? "Super Admin"
              : params?.row?.role === "saleAgent"
              ? "Sale Agent"
              : params.row?.role === "goldPartner"
              ? "Gold Partner"
              : params.row?.role}
          </Typography>
        ),
      },
      {
        field: "phone",
        headerName: "Phone",
        minWidth: 150,
        flex: 1,
        sortable: false,
        editable: true,
        headerAlign: "center",
        align: "center",
        renderCell: (params) => <Typography>{params?.row?.phone}</Typography>,
      },
      {
        field: "email",
        headerName: "Email",
        minWidth: 225,
        flex: 1,
        sortable: false,
        editable: true,
        renderCell: (params) => <Typography>{params?.row?.email}</Typography>,
      },
      {
        field: "actions",
        headerName: "Actions",
        headerAlign: "right",
        align: "right",
        minWidth: 150,
        flex: 0.5,
        disableColumnMenu: true,
        sortable: false,
        renderHeader: () => (
          <Typography variant="h6" pr={2}>
            Actions
          </Typography>
        ),
        renderCell: (params) => (
          <Box display="flex" pr={1}>
            {params?.row?.role !== USER_ROLE.SUPER_ADMIN &&
              loggedUserId !== params.row.id && (
                <IconButton
                  size="small"
                  onClick={() => navigate(`${params.id}/update`)}
                >
                  <img src={WritingIcon} alt="edit" />
                </IconButton>
              )}

            {loggedUserId !== params.row.id &&
              (loggedUserRole === USER_ROLE.SUPER_ADMIN ||
                loggedUserRole === USER_ROLE.ADMIN) &&
              params.row.role !== USER_ROLE.SUPER_ADMIN && (
                <IconButton
                  size="small"
                  onClick={() => handleClickOpen(params?.id)}
                >
                  <img src={deleteIcon} alt="edit" />
                </IconButton>
              )}
          </Box>
        ),
      },
    ],
    [handleClickOpen, loggedUserId, loggedUserRole, navigate]
  );
  return (
    <>
      <StyledDataGrid
        rows={users}
        columns={columns}
        loading={loading}
        totalPages={totalPages}
      />
      <ConfirmDelete deleteAction={deleteUser} />
    </>
  );
}
